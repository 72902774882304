import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueGoogleCharts from "vue-google-charts";
import moment from "moment";
// import titleMixin from './mixins/titleMixin'

// for authenticating thru headers with tokens
require("@/store/subscriber");
Vue.config.productionTip = false;
axios.defaults.baseURL = "https://mfh-server.jmbcomputers.com/api";
// axios.defaults.baseURL = "http://127.0.0.1:8000/api";

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    moment,
    VueGoogleCharts,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});
// FOR TESTING
