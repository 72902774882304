<template>
  <v-card max-width="300">
    <v-container class="">
      <v-row class="">
        <v-col>
          <!-- icon card -->
          <v-card :color="color" max-width="100" class="card_icon">
            <v-icon class="pa-6 d-flex justify-center" color="white" x-large>{{
              icon
            }}</v-icon>
          </v-card>
        </v-col>
        <v-col>
          <span class="grey--text">{{ title }}</span>
          <br />
          <span class="display-1 grey--text text--darken-1">{{ number }}</span>
        </v-col>
      </v-row>
      <v-row class="reload_row">
        <v-col
          ><v-btn small text color="primary lighten-3">
            <v-icon class="pr-2">mdi-refresh</v-icon> Reload
          </v-btn></v-col
        >
      </v-row>

      <v-divider></v-divider>

      <p class="grey--text pl-2 pt-2 caption">
        <v-icon small color="grey lighten-1">mdi-information</v-icon> Calculated
        as of today: {{ date_today }}
      </p>
    </v-container>
  </v-card>
</template>

<script>
import moment from "moment";
export default {
  props: ["color", "title", "icon", "data"],
  computed: {
    number() {
      var formatter = new Intl.NumberFormat("en-us", {});
      return formatter.format(this.data);
    },
    date_today() {
      var date = moment().format("MMM Do Y");
      return date;
    },
  },
};
</script>

<style scoped>
.card_icon {
  transform: translateY(-40px);
  transform: translateX();
}
.reload_row {
  transform: translateY(-40px);
  max-height: 20px;
}
</style>
