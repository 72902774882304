import axios from "axios";
export default {
  namespaced: true,
  state: {
    agents: null,
  },
  getters: {
    agents(state) {
      return state.agents;
    },
  },
  mutations: {
    SET_AGENTS(state, agents) {
      state.agents = agents;
    },
    ADD_AGENT(state, agent) {
      state.agents.unshift(agent);
    },
    EDIT_AGENT(state, agent) {
      var index = state.agents.findIndex((a) => a.id == agent.id);
      state.agents.splice(index, 1, agent);
    },
    DELETE_AGENT(state, agent) {
      let filtered = state.agents.filter((c) => c.id != agent.id);
      state.agents = filtered;
    },
  },
  actions: {
    // check if agent is already in the state

    // delete agent
    async delete_agent({ commit }, request) {
      let response = await axios.delete("/agent/" + request.id);
      if (response.data) {
        commit("DELETE_AGENT", request);
      }
    },
    // edit agent
    async edit_agent({ commit }, request) {
      // console.log(request);
      let response = await axios.put("/agent", request);
      commit("EDIT_AGENT", response.data);
    },
    // add agent
    async add_agent({ commit }, request) {
      let response = await axios.post("/agent", request);
      commit("ADD_AGENT", response.data);
    },
    // get agents
    async get_agents({ commit }, is_admin) {
      let response = await axios.get("/agents/" + is_admin);
      commit("SET_AGENTS", response.data);
    },

    async clear_agent({ commit }, request) {
      let response = await axios.post("/clear_agent", request);
      commit("EDIT_AGENT", response.data);
    },
  },
};
