export default {
  namespaced: true,
  state: {
    alert: {
      width: "350",
      color: "grey",
      title: "Sample Title",
      message:
        "lorem ipsum dolor sit amet, consectetur lorem ipsum dolor sit amet, consectetur?",
      showing: false,
      button_text: "I Understand",
    },

    notification: {
      message: "",
      color: "",
      icon: "",
      showing: false,
    },
    confirm_notification: {
      item: [],
      message:
        "lorem ipsum dolor sit amet, consectetur lorem ipsum dolor sit amet, consectetur?",
      icon: "mdi-help-circle",
      is_confirmed: false,
      type: "warning",
    },
  },
  getters: {
    alert(state) {
      return state.alert;
    },
  },
  mutations: {
    CLOSE_ALERT(state) {
      state.alert.showing = false;
    },
    SET_ALERT(state, alert) {
      state.alert = alert;
      // loop into each key in an object
      // Object.keys(state.alert).forEach((key)=>{
      //   /* if request alert object's property is present,
      //   replace state.alert's value */
      //   if(alert[key]){
      //     state.alert[key] = alert[key];
      //   }
      // });
    },
    CONFIRM(state) {
      state.confirm_notification.is_confirmed =
        !state.confirm_notification.is_confirmed;
      console.log(state.confirm_notification.is_confirmed);
    },
    SET_CONFIRM_NOTIFICATION(state, confirm_notification) {
      state.confirm_notification = confirm_notification;
    },
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
      setTimeout(() => {
        state.notification = {
          message: "",
          color: "",
          icon: "",
          showing: false,
        };
      }, 3000);
    },
  },
  actions: {
    confirm({ commit }) {
      commit("CONFIRM");
    },
    set_confirm_notification({ commit }, confirm_notification) {
      commit("SET_CONFIRM_NOTIFICATION", confirm_notification);
    },
    set_notification({ commit }, notification) {
      commit("SET_NOTIFICATION", notification);
    },
    set_alert({ commit }, alert) {
      commit("SET_ALERT", alert);
    },
    close_alert({ commit }) {
      commit("CLOSE_ALERT");
    },
  },
};
