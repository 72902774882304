<template>
  <v-container>
    <div class="pl-10">
      <div>
        <v-card class="">
          <v-card-text>
            <v-row>
              <v-col><contracts-modal /></v-col>
              <v-col class="ml-5">
                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  max-width="100"
                  placeholder="Enter a value..."
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              v-if="archived"
              dense
              :headers="contract_headers"
              :items="archived"
              multi-sort
              :search="search"
            >
              <!-- view profile -->
              <!-- <template v-slot:[`item.profile`]="{ item }">
                <div class="text-center">
                  <v-icon class="mr-2" @click="profile(item)" color="primary">
                    mdi-account-circle
                  </v-icon>
                  <span class="caption primary--text">
                    <strong>Contract</strong>
                  </span>
                </div>
              </template> -->
              <!-- view payments -->
              <!-- <template v-slot:[`item.payment`]="{ item }">
                <div class="text-center">
                  <v-icon
                    class="mr-2"
                    color="yellow en-2"
                    @click="payment(item)"
                  >
                    mdi-cash-multiple
                  </v-icon>
                  <span class="caption yellow--text text--en-2">
                    <strong>Payments</strong>
                  </span>
                </div>
              </template> -->
              <!-- contract status -->
              <template v-slot:[`item.contract_status`]="{ item }">
                <div class="text-center">
                  <div v-if="item.contract_status == 'Deceased'">
                    <v-chip color="red" class="white--text">Deceased</v-chip>
                  </div>
                  <div v-if="item.contract_status == 'Assigned'">
                    <v-chip color="yellow" class="">Assigned</v-chip>
                  </div>
                  <div v-if="item.contract_status == 'Canceled'">
                    <v-chip color="primary" class="white--text"
                      >Canceled</v-chip
                    >
                  </div>
                  <div v-if="item.contract_status == 'Plan Redated'">
                    <v-chip color="grey" class="white--text"
                      >Plan Redated</v-chip
                    >
                  </div>
                  <div v-if="item.contract_status == 'Expired'">
                    <v-chip color="teal" class="white--text">Expired</v-chip>
                  </div>
                  <div v-if="item.contract_status == 'Pending'">
                    <v-chip color="orange" class="white--text">Pending</v-chip>
                  </div>
                </div>
              </template>
              <template v-slot:[`item.restore`]="{ item }">
                <div class="d-flex">
                  <span class="text-center">
                    <v-btn
                      text
                      @click="permanently_delete(item)"
                      color="red"
                      icon
                      :loading="loading"
                      ><v-icon class="mr-2">mdi-delete</v-icon>
                    </v-btn>
                  </span>
                  <p
                    class="text-center overline orange--text font-size-small"
                    v-if="item.contract_status == 'Pending'"
                  >
                    For Verification
                  </p>
                  <span class="text-center" v-else>
                    <v-btn text @click="restore_item(item)" color="green"
                      ><v-icon class="mr-2">mdi-undo</v-icon>
                    </v-btn>
                  </span>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
      <progress-linear :dialog="progress_dialog" />
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ContractsModal from "../components/ContractsModal.vue";
import ProgressLinear from "../components/ProgressLinear.vue";
export default {
  name: "Dashboard",
  data() {
    return {
      loading: false,
      progress_dialog: false,
      search: "",
      contract_headers: [
        { text: "Action", value: "restore" },
        { text: "ID", value: "id" },
        {
          text: "Contract Number",
          value: "contract_no",
        },
        {
          text: "Customer Name",
          value: "customer_name",
        },
        {
          text: "Plan Type",
          value: "plan_type",
        },
        {
          text: "Agent",
          value: "agent",
        },
        {
          text: "Insurable",
          value: "insurable",

          width: "100",
        },
        {
          text: "Installment Value",
          value: "installment_value",
        },
        {
          text: "Date Created",
          value: "date_created",
        },
        { text: "Contract Status", value: "contract_status" },
      ],
    };
  },
  components: { ContractsModal, ProgressLinear },
  methods: {
    permanently_delete(item) {
      // check if admin
      if (this.user.role != "Administrator") {
        return alert("You are not allowed to do this action");
      }
      let x = window.confirm(
        "Are you sure you want to delete this contract permanently?"
      );
      if (x) {
        this.loading = true;
        this.delete_permanently(item.id)
          .then(() => {
            alert("Successfully deleted a contract!");
            this.loading = false;
          })
          .catch((err) => {
            alert(err);
          });
      }
    },
    restore_item(item) {
      if (this.user.role != "Administrator") {
        return alert("You are not allowed to do this");
      }
      this.progress_dialog = true;

      this.restore(item).then(() => {
        this.progress_dialog = false;
      });
    },
    ...mapActions({
      get_archives: "contract/get_archived_contracts",
      restore: "contract/restore",
      get_contracts: "contract/get_contracts",
      delete_permanently: "contract/delete_permanently",
    }),
  },
  created() {
    this.progress_dialog = true;
    if (!this.contracts) {
      this.get_contracts().then(() => {
        if (!this.archived) {
          this.get_archives().then(() => {
            this.progress_dialog = false;
          });
        } else {
          this.progress_dialog = false;
        }
      });
    } else {
      if (!this.archived) {
        this.get_archives().then(() => {
          this.progress_dialog = false;
        });
      } else {
        this.progress_dialog = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      archived: "contract/archived",
      contracts: "contract/contracts",
      user: "auth/user",
    }),
  },
};
</script>
