<template>
  <div>
    <v-btn class="rounded-xl white--text" color="blue" @click="open"
      ><v-icon>mdi-account</v-icon>Contracts</v-btn
    >
    <v-dialog v-model="dialog" width="1100" persistent>
      <v-card>
        <v-sheet class="pa-5 white--text" color="blue">
          <h2>Search for a contract</h2>
        </v-sheet>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                prepend-icon="mdi-clipboard-list"
                label="Contract Number"
                v-model="contract_no"
                class="mt-2"
              ></v-text-field
            ></v-col>
            <v-col>
              <div class="d-flex mt-6">
                <p class="mr-5 mt-1">Actions:</p>
                <v-btn
                  small
                  class="rounded-xl mr-2"
                  color="yellow"
                  :disabled="found_contracts.length == 0"
                  @click="change_action('Assigned')"
                  >Assign</v-btn
                ><v-btn
                  small
                  class="rounded-xl mr-2"
                  color="primary"
                  :disabled="found_contracts.length == 0"
                  @click="change_action('Canceled')"
                  >Cancel</v-btn
                >
                <v-btn
                  small
                  class="rounded-xl mr-2 white--text"
                  color="red"
                  :disabled="found_contracts.length == 0"
                  @click="change_action('Deceased')"
                  >Deceased</v-btn
                >
                <v-btn
                  small
                  class="rounded-xl mr-2 white--text"
                  color="success"
                  :disabled="found_contracts.length == 0"
                  @click="redate_custom_date = true"
                  >Redate</v-btn
                >
                <v-btn
                  small
                  class="rounded-xl mr-2 white--text"
                  color="warning"
                  :disabled="found_contracts.length == 0"
                  @click="change_action('Expired')"
                  >Expired</v-btn
                >
                <v-btn
                  small
                  class="rounded-xl mr-2 white--text"
                  color="pink"
                  :disabled="found_contracts.length == 0"
                  @click="change_action('Forfeit')"
                  >Forfeit</v-btn
                >
                <v-btn
                  small
                  class="rounded-xl mr-2 white--text"
                  color="pink"
                  :disabled="found_contracts.length == 0"
                  @click="change_action('Loan')"
                  >Loan</v-btn
                >
              </div>
              <v-dialog v-model="redate_custom_date" width="400" persistent>
                <v-card class="pa-5">
                  <h3 class="mb-2">Plan Redate</h3>
                  <custom-date-picker
                    icon="mdi-calendar"
                    label="Plan Redate Date"
                    placeholder="Click to enter a date"
                    @set_date="set_date"
                  />
                  <div class="d-flex justify-end">
                    <v-btn
                      class="rounded-xl mt-n4"
                      small
                      color="primary"
                      @click="change_action('Redate')"
                      >Submit</v-btn
                    >
                    <v-btn
                      class="rounded-xl mt-n4 ml-2"
                      small
                      color=""
                      @click="redate_custom_date = false"
                      >Close</v-btn
                    >
                  </div>
                </v-card>
              </v-dialog>
            </v-col></v-row
          >

          <div v-if="table_ready" class="mb-5">
            <h2 class="mb-2">Search Results</h2>
            <v-simple-table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Contract Number</th>
                  <th>Customer Name</th>
                  <th>Plan Type</th>
                  <th>Agent</th>
                  <th>Insurable</th>
                  <th>Installment Value</th>
                  <th>Date Created</th>
                  <th>Contract Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="contract in found_contracts" :key="contract.id">
                  <td>{{ contract.id }}</td>
                  <td>{{ contract.contract_no }}</td>
                  <td>{{ contract.customer_name }}</td>
                  <td>{{ contract.plan_type }}</td>
                  <td>
                    {{ contract.agent.surname }}, {{ contract.agent.name }}
                  </td>
                  <td>{{ contract.insurable }}</td>
                  <td>{{ contract.installment_value }}</td>
                  <td>{{ contract.date_created }}</td>
                  <td>{{ contract.contract_status }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-divider></v-divider>
          </div>
          <div class="d-flex">
            <v-btn
              class="rounded-xl mr-2 white--text"
              @click="search"
              color="blue"
              :loading="button_loading"
              >Search</v-btn
            >
            <v-btn class="rounded-xl" @click="close">Close</v-btn>
            <v-spacer></v-spacer>
            <p class="caption">ContractsModal.vue</p>
          </div>
        </v-card-text>
      </v-card>
      <div v-if="show_alert">
        <confirm-notification
          icon="mdi-alert-outline"
          class="alert"
          type="warning"
          :message="this.alert_message"
          @confirm-event="proceed_change"
          @cancel-event="close_confirm"
        />
      </div>
      <progress-linear :dialog="progress_linear" />
    </v-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import ConfirmNotification from "@/components/ConfirmNotification";
import ProgressLinear from "./ProgressLinear.vue";
// import CustomDatePicker from "./CustomDatePicker.vue";
export default {
  components: {
    ConfirmNotification,
    ProgressLinear,
    CustomDatePicker: () => import("./CustomDatePicker.vue"),
  },
  data() {
    return {
      redate_custom_date: false,
      dialog: false,
      found_contracts: [],
      contract_no: "",
      table_ready: false,
      button_loading: false,
      show_alert: false,
      alert_message: "",
      action: "",
      progress_linear: false,
    };
  },
  created() {},
  methods: {
    ...mapActions({
      search_contract: "contract/search_contract",
      change_contract_status: "contract/change_contract_status",
      redate: "contract/redate",
      set_alert: "notification/set_alert",
    }),
    set_date(date) {
      var new_date = date.year + "-" + date.month + "-" + date.day;
      this.found_contracts[0].date_created = new_date;
    },
    async change_action(action) {
      if (this.found_contracts.length > 1) {
        alert(
          "Two contracts are found which might cause an updated problem. Please contact your administrator."
        );
      } else {
        // check if redate
        if (action == "Redate") {
          this.progress_linear = true;

          await this.redate(this.found_contracts[0]);
          this.progress_linear = false;
          this.set_alert({
            button_text: "I Understand",
            width: "500",
            showing: true,
            color: "blue",
            title: "Contract Redate",
            message:
              "Note: Redate functionality reduces the Number of Installment in a contract and changes the Application Date into the present date. After the process, the specified contract's PAYMENTS will be deleted PERMANENTLY.",
          });
          alert("Contract Redated");
        } else {
          this.action = action;
          this.alert_message =
            "Are you sure you want to apply changes to this contract?";
          this.show_alert = true;
        }
      }
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    close_confirm() {
      this.show_alert = false;
    },
    proceed_change() {
      this.progress_linear = true;
      var request = {
        id: this.found_contracts[0].id,
        action: this.action,
      };
      // console.log(request);
      this.change_contract_status(request).then(() => {
        this.progress_linear = false;
        this.close();
        alert("saved");
      });
    },
    search() {
      this.found_contracts = [];
      this.button_loading = true;
      this.search_contract(this.contract_no).then((data) => {
        if (data) {
          this.found_contracts.push(data);
          // console.log(this.response.data);
          this.table_ready = true;
          this.button_loading = false;
        } else {
          alert("No data found!");
          this.button_loading = false;
        }
      });
    },
  },
};
</script>
