<template>
  <v-container>
    <div class="pl-10 pt" v-if="ready">
      <v-row class="mb-5">
        <!-- CONTRACTS -->
        <v-col>
          <TotalCard
            class="mt-13"
            color="green"
            icon="mdi-clipboard-list"
            title="Total Contracts-Month"
            :data="contract_summary[0].total_contract_this_month"
          />
        </v-col>
        <!-- SALES -->
        <v-col>
          <TotalCard
            class="mt-13"
            color="yellow darken-2"
            icon="mdi-cash-multiple"
            title="Total Sales this month"
            :data="contract_summary[0].total_new_sales_this_month"
          />
        </v-col>
        <!-- AGENTS -->
        <v-col>
          <TotalCard
            class="mt-13"
            color="red"
            icon="mdi-face-agent"
            title="Active Agents"
            :data="contract_summary[0].total_agents"
        /></v-col>
        <v-col>
          <v-card class="mt-13">
            <v-card-title>No. of Contracts Paid</v-card-title>
            <v-card-text>
              <ul>
                <li
                  v-for="(ave, index) in average_number_of_payments_per_month"
                  :key="ave"
                >
                  {{ index }} - {{ ave }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <div class="mb-10">
        <v-card>
          <v-card-text>
            <h2 class="">Number of New Contracts</h2>
            <p>
              The graph shows the number of contracts added from the last 12
              months
            </p>
            <GChart
              type="ColumnChart"
              :data="contract_summary[0].summary"
              :options="chartOptions"
            />

            <h2 class="mt-7">Summary of Sales</h2>
            <p>The graph shows the total sales from the last 12 months</p>
            <GChart
              type="ColumnChart"
              :data="contract_summary[0].sales"
              :options="chartOptions"
            />
          </v-card-text>
        </v-card>
      </div>
    </div>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>

<script>
import { GChart } from "vue-google-charts";
// import Graph from "@/components/Graph.vue";
import { mapActions, mapGetters } from "vuex";
import TotalCard from "@/components/TotalCard.vue";
import ProgressLinear from "@/components/ProgressLinear.vue";
import axios from "axios";
export default {
  name: "Dashboard",

  components: { TotalCard, GChart, ProgressLinear },
  data() {
    return {
      ready: false,
      progress_dialog: false,
      contracts_gradient: ["#00c6ff", "#F0F", "#FF0"],
      sales_gradient: ["red", "orange", "yellow"],
      average_number_of_payments_per_month: [],
      chartOptions: {
        chart: {
          title: "Box Office Earnings in First Two Weeks of Opening",
          subtitle: "in millions of dollars (USD)",
        },
        curveType: "function",
      },
    };
  },
  computed: {
    ...mapGetters({
      contracts: "contract/contracts",
      contract_summary: "contract/contract_summary",
    }),
  },
  methods: {
    ...mapActions({
      on_page_reload: "auth/on_page_reload",
      get_contract_summary: "contract/get_contract_summary",
    }),

    async get_number_of_payments_per_month() {
      let response = await axios.get("/average_number_of_payments_per_month");
      this.average_number_of_payments_per_month = response.data;
      console.log(this.average_number_of_payments_per_month);
    },
  },

  created() {
    this.progress_dialog = !this.progress_dialog;
    this.get_number_of_payments_per_month();
    if (!this.contract_summary) {
      this.get_contract_summary().then(() => {
        this.progress_dialog = !this.progress_dialog;
        this.ready = true;
      });
    } else {
      this.progress_dialog = !this.progress_dialog;
      this.ready = true;
    }
  },
};
</script>
<style scoped></style>
