<template>
  <v-app
    color="primary "
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <div>
      <TheNavBar />
      <TopNavBar :page_name="currentRouteName" />
      <v-main>
        <!-- alert notification -->
        <notification />
        <alert />
        <transition name="router_anim" mode="out-in">
          <router-view />
        </transition>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TopNavBar from "@/components/TopNavBar.vue";
import TheNavBar from "@/components/TheNavBar.vue";
import Notification from "./components/Notification.vue";
import Alert from "./components/Alert.vue";

export default {
  components: {
    TheNavBar,
    TopNavBar,
    Notification,
    Alert,
  },
  name: "App",

  data() {
    return {
      page_ready: true,
    };
  },
  created() {
    document.title = "MOP - " + this.currentRouteName;
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      contracts: "contract/contracts",
      is_logged: "auth/is_logged",
    }),
    currentRouteName() {
      return this.$route.name;
    },

    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    change_route_name(e) {
      console.log(e);
    },
    ...mapActions({
      attempt: "auth/attempt",
    }),
  },
};
</script>
<style>
.title {
  font-size: 120px;
}
.router_anim-enter {
  opacity: 0;
  transform: translateX(-200px);
}
.router_anim-enter-active {
  transition: all 0.2s ease;
}
.router_anim-leave-to {
  opacity: 0;
  transform: translateX(200px);
}
.router_anim-leave-active {
  transition: all 0.2s ease;
}
/* .page {
  position: fixed;
  width: inherit;
} */
</style>
