import axios from "axios";
export default {
  namespaced: true,
  state: {
    // state of app. can store anything from strings to objects
    token: null,
    user: null,
    page_title: "",
    appVersion: "0.1.2 6-22-22",
  },
  // read info from state
  // sa
  getters: {
    appVersion(state) {
      return state.appVersion;
    },
    is_logged(state) {
      return state.is_logged;
    },
    authenticated(state) {
      return state.token && state.user;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    default_server_url() {
      // return "http://127.0.0.1:8000/";
      return "https://mfh-server.jmbcomputers.com/";
    },
    loading(state) {
      return state.page_loading;
    },
    page_title(state) {
      return state.page_title;
    },
  },
  mutations: {
    // update the state
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    SET_PAGE_TITLE(state, page_title) {
      state.page_title = page_title;
    },
  },
  actions: {
    // set page title
    async set_page_title({ commit }, page_title) {
      commit("SET_PAGE_TITLE", page_title);
    },
    // make request to api but commit to mutations
    async signin({ dispatch }, credentials) {
      let response = await axios.post("login", credentials);
      return dispatch("attempt", response.data);
    },
    // attempt to access me api to check if token is valid
    // and give us back the users info
    async attempt({ commit, state }, token) {
      // if there is a token, commit
      if (token) {
        commit("SET_TOKEN", token);
      }
      // if there is no token in the sate, stop the execution
      if (!state.token) {
        return;
      }
      // check token if it is valid in me api
      try {
        // pass header for authorization
        let response = await axios.get("attempt");
        // if it is true, set_user to response ~
        commit("SET_USER", response.data);
        // get services
        // this.$store.dispatch('services/get', response.data.branch_id, { root: true });
      } catch (e) {
        // if errors occurs set state to null
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      }
    },
    signout({ commit }) {
      return axios.post("signout").then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
      });
    },
  },
};
