<template>
  <div>
    <div class="text-center">
      <v-icon class="mr-2" color="yellow " @click="open()"> mdi-pencil </v-icon>
      <span class="caption yellow--text">
        <strong>Edit</strong>
      </span>
    </div>
    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-sheet color="blue darken-2" class="pa-5 white--text">
          <h2>Edit Account</h2>
        </v-sheet>
        <v-card-text>
          <div class="mt-5">
            <v-select
              :rules="rules.required"
              class="rounded-xl filled"
              v-model="item.account_type"
              :items="account_type"
              prepend-icon="mdi-account-cog"
              filled
              label="Account Type"
            ></v-select>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.username"
              label="Username"
              prepend-icon="mdi-account-outline"
            ></v-text-field>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.password"
              label="Password"
              placeholder="Please enter a new password"
              prepend-icon="mdi-form-textbox-password"
            ></v-text-field>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.first_name"
              label="First Name"
              prepend-icon="mdi-account-outline"
            ></v-text-field>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.middle_name"
              label="Middle Name"
              prepend-icon="mdi-account-outline"
            ></v-text-field>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.last_name"
              label="Last Name"
              prepend-icon="mdi-account-outline"
            ></v-text-field>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.gender"
              label="Gender"
              prepend-icon="mdi-gender-male-female"
            ></v-text-field>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.address"
              label="Address"
              prepend-icon="mdi-map-marker"
            ></v-text-field>
            <v-text-field
              :rules="rules.required"
              class="rounded-xl"
              filled
              v-model="item.phone_number"
              label="Phone Number"
              prepend-icon="mdi-phone"
            ></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="rounded-xl" @click="submit_edit"
            >Submit</v-btn
          >
          <v-btn color="" class="rounded-xl" @click="close_modal">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <div v-if="show_alert">
        <confirm-notification
          icon="mdi-alert-outline"
          class="alert"
          type="warning"
          :message="this.alert_message"
          @confirm-event="proceed_edit"
          @cancel-event="close"
        />
      </div>
      <progress-linear :dialog="progress_dialog" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfirmNotification from "./ConfirmNotification.vue";
import ProgressLinear from "./ProgressLinear.vue";
export default {
  components: { ConfirmNotification, ProgressLinear },
  props: ["item"],
  data() {
    return {
      rules: {
        required: [(value) => !!value || "Field is required"],
      },
      account_type: [
        "Administrator",
        "Verifier",
        "Cashier",
        "Collector",
        "Encoder",
      ],
      progress_dialog: false,
      dialog: false,
      show_alert: false,
      alert_message: "Are you sure you want to edit this beneficiary?",
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      edit_account: "account/edit_account",
      set_notification: "notification/set_notification",
    }),
    open() {
      this.dialog = true;
    },
    close_modal() {
      this.dialog = false;
    },
    submit_edit() {
      //   let self = this;
      var x = this.item;
      if (confirm("Are you sure you want to proceed?")) {
        this.progress_dialog = true;
        console.log(x);
        this.edit_account(x).then(() => {
          this.set_notification({
            message: "You have successfully edited an item",
            color: "success",
            showing: true,
            icon: "mdi-check",
          });
          this.progress_dialog = false;
        });
      }
      //   this.show_alert = true;
    },
    close() {
      this.show_alert = false;
    },
  },
};
</script>

<style></style>
