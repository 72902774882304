<template>
  <v-container>
    <div class="pl-10">
      <div>
        <v-card class="">
          <v-card-text>
            <add-account-modal />
            <v-row>
              <v-col cols="4" class="ml-5"> </v-col>
            </v-row>
            <v-data-table
              v-if="accounts"
              :search="search"
              :items="accounts"
              :headers="headers"
            >
              <!-- edit account -->
              <template v-slot:[`item.edit`]="{ item }">
                <edit-account-modal :item="item" />
              </template>
              <!-- delete account -->
              <template v-slot:[`item.delete`]="{ item }">
                <div class="text-center">
                  <v-icon class="mr-2" color="red " @click="del_account(item)">
                    mdi-delete
                  </v-icon>
                  <span class="caption red--text">
                    <strong>Delete</strong>
                  </span>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
import AddAccountModal from "../components/AddAccountModal.vue";
import EditAccountModal from "../components/EditAccountModal.vue";
export default {
  name: "Dashboard",

  components: { ProgressLinear, AddAccountModal, EditAccountModal },
  computed: {
    ...mapGetters({
      accounts: "account/accounts",
    }),
  },
  data() {
    return {
      search: "",
      progress_dialog: false,
      headers: [
        { text: "", value: "edit" },
        { text: "", value: "delete" },
        { text: "Account ID", value: "id" },
        { text: "First Name", value: "first_name" },
        { text: "Middle Name", value: "middle_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Gender", value: "gender" },
        { text: "Address", value: "address" },
        { text: "Username", value: "username" },
        { text: "Account Role", value: "role" },
        { text: "Phone Number", value: "phone_number" },
      ],
    };
  },
  created() {
    this.progress_dialog = true;
    if (!this.accounts) {
      this.get_accounts().then(() => {
        this.progress_dialog = false;
      });
    } else {
      this.progress_dialog = false;
    }
  },

  methods: {
    ...mapActions({
      get_accounts: "account/get_accounts",
      delete_account: "account/delete_account",
      set_notification: "notification/set_notification",
    }),

    del_account(item) {
      if (confirm("Are you sure you want to proceed?")) {
        this.progress_dialog = true;
        this.delete_account(item).then(() => {
          this.progress_dialog = false;
          this.set_notification({
            message: "You have successfully deleted an agent",
            color: "success",
            showing: true,
            icon: "mdi-check",
          });
        });
      }
    },
  },
};
</script>
