<template>
  <v-container>
    <div v-if="ready">
      <!-- <v-img src="@/assets/HEADER.png" max-width="1200"></v-img> -->
      <v-card class="mb-5">
        <v-card-text>
          <h2>Contract Information</h2>
          <v-row>
            <v-col>
              <span> Plan Holder's Name:</span>
              <p class="display-1 blue--text">
                {{ contract.customer_name }}
              </p>
            </v-col>
            <v-col>
              <span> Plan Holder's Address:</span>
              <p class="display-1">
                {{ contract.customer_address }}
              </p>
            </v-col>
          </v-row>

          <v-simple-table>
            <thead>
              <tr>
                <th>Application Date</th>
                <th>End Date</th>
                <th>Plan Type</th>
                <th>Billing Cycle</th>
                <th>Installments Number</th>
                <th>Installment Value</th>
                <th>Paid Installments</th>
                <th>Months to Pay</th>
                <th>Months Delayed</th>
              </tr>
            </thead>
            <tbody>
              <tr class="grey lighten-3">
                <td>{{ modified_date_created.application_date }}</td>
                <td>{{ modified_date_created.end_date }}</td>
                <td>{{ contract.plan_type }}</td>
                <td>{{ contract.mode_of_installments }}</td>
                <td>{{ contract.installments_number }}</td>
                <td>{{ contract.installment_value }}</td>
                <td>{{ total_number_of_payments }}</td>
                <td>{{ mtp }}</td>
                <td>{{ md }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider></v-divider>
          <v-row class="mt-2 ml-1 flex-row justify-center">
            <v-col>
              <h4>Contract Number: {{ contract.contract_no }}</h4>
            </v-col>
            <v-col>
              <h4>Contract ID: {{ contract.id }}</h4>
            </v-col>
            <v-col>
              <h4>Agent Name: {{ agent }}</h4>
            </v-col>
            <v-col cols="1">
              <h4>Status: {{ contract.contract_status }}</h4>
            </v-col>
            <v-col>
              <h4>Updated: {{ format_date(contract.updated_at) }}</h4>
            </v-col>
            <v-col>
              <h4>Created:{{ format_date(contract.created_at) }}</h4>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card>
        <!-- <p class="display-1">Payments History Table</p> -->
        <v-card-text>
          <v-row class="ml-5">
            <v-col>
              <v-text-field
                placeholder="Search anything..."
                prepend-inner-icon="mdi-magnify"
                max-width="300"
                v-model="search"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="ml-">
              <v-btn
                small
                class="ml-10 white--text rounded-xl"
                color="blue"
                @click="open_payment()"
                >Add Payment</v-btn
              >
              <v-btn
                small
                class="ml-2 rounded-xl"
                color=""
                @click="print_payment"
                >Print Table</v-btn
              >
            </v-col>
          </v-row>

          <v-data-table
            :search="search"
            :items="payment"
            :headers="headers"
            :options="options"
            dense
          >
            <!-- edit payment -->
            <template v-slot:[`item.edit`]="{ item }">
              <div class="text-center" v-if="item.payment_date != 'unpaid'">
                <v-icon class="mr-2" color="yellow " @click="edit(item)">
                  mdi-pencil
                </v-icon>
                <span class="caption yellow--text">
                  <strong>Edit</strong>
                </span>
              </div>
              <div class="text-center" v-else>
                <v-icon class="mr-2" color="grey "> mdi-pencil </v-icon>
                <span class="caption grey--text">
                  <strong>Edit</strong>
                </span>
              </div>
            </template>
            <!-- delete payment -->
            <template v-slot:[`item.delete`]="{ item }">
              <div>
                <div class="text-center" v-if="item.payment_date != 'unpaid'">
                  <v-icon
                    class="mr-2"
                    color="red "
                    @click="delete_payment(item)"
                  >
                    mdi-delete
                  </v-icon>
                  <span class="caption red--text">
                    <strong>Delete</strong>
                  </span>
                </div>
                <div class="text-center" v-else>
                  <v-icon class="mr-2" color="grey ">mdi-delete</v-icon>
                  <span class="caption grey--text">
                    <strong>Delete</strong>
                  </span>
                </div>
              </div>
            </template>

            <!-- installment number -->
            <template v-slot:[`item.installment_number`]="{ item }">
              <div
                class="text-center green--text"
                v-if="item.payment_date != 'unpaid'"
              >
                <v-icon class="mr-2" color="green "> mdi-check </v-icon>
                {{ item.installment_number }}
              </div>
              <div class="text-center red--text" v-else>
                <v-icon class="mr-2" color="red "> mdi-close </v-icon>
                {{ item.installment_number }}
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <!-- <div v-else>
      <progress-linear :dialog="true" />
    </div> -->
    <div v-if="add_payment_modal">
      <add-payment-modal
        @close_add_payment_modal="close_add_payment_modal"
        :contract="this.contract"
      />
    </div>
    <div v-if="edit_payment_modal">
      <edit-payment-modal
        :item="edit_payment_item"
        @close_edit_payment_modal="close_edit_payment_modal"
      />
    </div>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="proceed_deletion"
        @cancel-event="close_confirm"
      />
    </div>
    <loading-alert-modal
      :dialog="loading_alert_modal"
      :data="loading_alert_data"
      :id="loading_alert_id"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import ProgressLinear from "@/components/ProgressLinear.vue";
import AddPaymentModal from "../components/AddPaymentModal.vue";
import moment from "moment";
import EditPaymentModal from "../components/EditPaymentModal.vue";
import ConfirmNotification from "@/components/ConfirmNotification.vue";
import LoadingAlertModal from "../components/LoadingAlertModal.vue";
export default {
  components: {
    // ProgressLinear,
    AddPaymentModal,
    EditPaymentModal,
    ConfirmNotification,
    LoadingAlertModal,
  },

  data() {
    return {
      loading_alert_modal: false,
      loading_alert_data: "",
      loading_alert_id: "",

      ready: false,
      add_payment_item: "",
      add_payment_modal: false,

      edit_payment_item: "",
      edit_payment_modal: false,

      delete_item: "",

      show_alert: false,
      alert_message: "",

      //  delete_payment_item: "",
      //     delete_payment_modal: false,

      search: "",

      options: {
        itemsPerPage: 60,
      },
      headers: [
        {
          text: "",
          value: "edit",
        },
        {
          text: "",
          value: "delete",
        },

        {
          text: "Installment Number",
          value: "installment_number",
          width: "150px",
        },
        {
          text: "Payment for the month of",
          value: "payment_for_the_month_of",
        },
        {
          text: "Payment Date",
          value: "payment_date",
        },
        {
          text: "Amount Paid",
          value: "amount_paid",
        },
        {
          text: "PR Number",
          value: "pr_number",
        },
        {
          text: "Posted By",
          value: "posted_by",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      get_payment: "payment/get_payment",
      get_contract: "contract/get_contract",
      get_contracts: "contract/get_contracts",
      get_agents: "agent/get_agents",
      del_payment: "payment/delete_payment",
      set_notification: "notification/set_notification",
    }),
    open_payment() {
      if (this.user.role == "Administrator" || this.user.role == "Cashier") {
        this.add_payment_modal = true;
      } else {
        alert("You are not allowed to do this!");
      }
    },

    format_date(date) {
      var new_date = moment(date).format("LLLL");
      return new_date;
    },
    print_payment() {
      var url =
        this.default_server_url +
        "payment/" +
        this.token +
        "/" +
        this.$route.params.id;
      window.open(url);
    },
    edit(item) {
      if (this.user.permission != 4) {
        alert("You are not allowed to do this action");
      } else {
        this.edit_payment_item = item;
        this.edit_payment_modal = true;
      }
    },
    close_add_payment_modal() {
      this.add_payment_modal = false;
    },
    close_edit_payment_modal() {
      this.edit_payment_modal = false;
    },
    close_confirm() {
      this.show_alert = false;
    },
    delete_payment(item) {
      if (this.user.permission != 4) {
        alert("You are not allowed to do this action");
      } else {
        this.show_alert = true;
        this.alert_message = "Are you sure you want to proceed?";
        this.delete_item = item;
      }
    },
    close_delete() {
      this.show_alert = false;
      this.delete_item = "";
    },
    proceed_deletion() {
      this.show_alert = false;
      this.progress_dialog = true;
      // insert contract id into delete_item
      this.delete_item.contract_id = this.$route.params.id;
      this.del_payment(this.delete_item).then(() => {
        this.progress_dialog = false;
        this.set_notification({
          message: "You have successfully deleted an item",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      });
    },
    set_alert(data, id) {
      this.loading_alert_data = data;
      this.loading_alert_id = id;
      this.loading_alert_modal = true;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      payment: "payment/payment",
      total_number_of_payments: "payment/total_number_of_payments",
      contracts: "contract/contracts",
      agents: "agent/agents",
      contract: "contract/contract",
      default_server_url: "auth/default_server_url",
      token: "auth/token",
    }),
    modified_date_created() {
      var x = this.contract.date_created.split(" ");
      let application_date = moment(x[0], "Y-M-D").format("Y-MMM-D");
      var end_date = moment(application_date.toString())
        .add(this.contract.installments_number - 1, "months")
        .format("Y-MMM-D");
      return { application_date, end_date };
    },
    agent() {
      const agent = this.agents.find((a) => a.id === this.contract.agent_id);
      console.log("total num of payments", this.total_number_of_payments);
      // check if agent exist or agent is ACCOUNT TURN OVER or AGENT is LESS THAN 12 payments, display the name of agent.
      if (
        agent ||
        this.contract.agent_id == 88 ||
        this.total_number_of_payments < 12
      ) {
        return `${agent.surname}, ${agent.name}`;
      }
      return "ACCOUNT, OFFICER";
    },

    mtp() {
      // date difference from application date to current date
      let x = this.contract.date_created.split(" ");
      let application_date = moment(x[0], "Y-M-D");
      let current_date = moment();
      let diff = current_date.diff(application_date, "months") + 1;
      if (diff > 60) diff = 60;
      return diff;
    },
    md() {
      // get total mtp - total payment rows
      let mtp = this.mtp;
      let total_rows = this.payment.filter((p) => {
        return p.amount_paid != "n/a";
      }).length;
      let md = mtp - total_rows;
      return md;
    },
  },
  async created() {
    var id = this.$route.params.id;
    this.set_alert("Required Data", "set_loading");
    if (!this.contracts) {
      await this.get_contracts();
      this.set_alert("Loading Contracts", "loading_contract");
    }
    if (!this.agents) {
      await this.get_agents();
      this.set_alert("Loading Agents", "loading_agent");
    }
    this.set_alert("Setting Contract", "set_contract");
    await this.get_contract(id);
    this.set_alert("Setting Payment", "set_payment");
    await this.get_payment(id);
    this.loading_alert_modal = false;
    this.ready = true;
    // console.log("ready?", this.ready);
  },
};
</script>

<style></style>
