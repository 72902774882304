<template>
  <div>
    <v-dialog v-model="dialog" width="1200">
      <v-card>
        <v-sheet color="blue darken-2" class="pa-5">
          <v-row width="1200">
            <v-col>
              <h1 class="display-1 font-weight-thin white--text">
                <v-icon x-large color="white" class="mr-5"
                  >mdi-account-group-outline</v-icon
                >Add Beneficiary
              </h1>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-sheet>
        <v-card-text class="mt-5">
          <h2>Beneficiaries</h2>
          <v-data-table
            :items="this.beneficiaries"
            :headers="beneficiary_headers"
          >
            <!-- edit beneficiary -->
            <template v-slot:[`item.edit_beneficiary`]="{ item }">
              <div class="text-center">
                <v-icon
                  class="mr-2"
                  @click="edit_beneficiary(item)"
                  color="yellow en-2"
                >
                  mdi-pencil
                </v-icon>
                <span class="caption yellow--text text--en-2">
                  <strong>Edit</strong>
                </span>
              </div>
            </template>
            <!-- delete beneficiary -->
            <template v-slot:[`item.delete_beneficiary`]="{ item }">
              <div class="text-center">
                <v-icon
                  class="mr-2"
                  color="error"
                  @click="confirm_delete(item)"
                >
                  mdi-delete
                </v-icon>
                <span class="caption error--text">
                  <strong>Delete</strong>
                </span>
              </div>
            </template>
          </v-data-table>

          <h2>Add Beneficiary Form</h2>
          <v-form ref="form">
            <v-row>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Beneficiary Name"
                  v-model="form.name"
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-cake"
                  label="Birthdate"
                  v-model="form.birth_date"
                  :rules="rules"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  prepend-icon="mdi-account-group"
                  label="Relationship"
                  v-model="form.relationship"
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  label="Address"
                  v-model="form.address"
                  :rules="rules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-row justify-end">
              <v-btn
                small
                class="white--text rounded-xl"
                color="primary "
                @click="confirm_add_beneficiary"
                >Add</v-btn
              >
              <v-btn @click="close_dialog" class="rounded-xl">Close</v-btn>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-right">
          <p class="caption grey--text">
            <v-icon class="mr-2" small>mdi-information</v-icon>
            Note: Beneficiaries can only be edited by the Verifier and the
            Admin.
          </p>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div v-if="edit_beneficiary_dialog">
      <edit-beneficiary-modal
        :item="edit_beneficiary_item"
        @close_edit_beneficiary_modal="close_edit_modal"
      />
    </div>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="proceed_add"
        @cancel-event="close_add"
      />
    </div>
    <div v-if="show_alert_delete">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="proceed_delete"
        @cancel-event="close_delete"
      />
    </div>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ConfirmNotification from "./ConfirmNotification.vue";
import EditBeneficiaryModal from "./EditBeneficiaryModal.vue";
import ProgressLinear from "./ProgressLinear.vue";
export default {
  props: { dialog: Boolean, beneficiaries: Array },
  components: { ConfirmNotification, EditBeneficiaryModal, ProgressLinear },
  data() {
    return {
      progress_dialog: false,
      delete_item: null,
      edit_beneficiary_dialog: false,
      edit_beneficiary_item: null,
      rules: [(value) => !!value || "required"],
      show_alert: false,
      show_alert_delete: false,
      alert_message: "Are you sure you want to edit this beneficiary?",
      form: {
        name: "",
        birth_date: "",
        address: "",
        relationship: "",
        contract_id: "",
      },

      beneficiary_headers: [
        { text: "Edit Beneficiary", value: "edit_beneficiary" },
        { text: "Delete Beneficiary", value: "delete_beneficiary" },

        { text: "Beneficiary ID", value: "id" },
        { text: "Contract ID", value: "contract_id" },
        { text: "Beneficiary Name", value: "name" },
        { text: "Birthdate", value: "birth_date" },
        { text: "Relationship", value: "relationship" },
        { text: "Address", value: "address" },
      ],
    };
  },
  async mounted() {
    // console.log(this.beneficiaries);
    // check if admin or not
    if (!this.user.permission == 4) {
      this.beneficiary_headers.splice(1, 1);
    }
  },
  computed: {
    ...mapGetters({
      contracts: "contract/contracts",
      contract: "contract/contract",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      add_beneficiary: "contract/add_beneficiary",
      delete_beneficiary: "contract/delete_beneficiary",
    }),
    close_edit_modal() {
      this.edit_beneficiary_dialog = false;
    },
    edit_beneficiary(item) {
      this.edit_beneficiary_dialog = true;
      this.edit_beneficiary_item = item;
    },
    confirm_delete(item) {
      if (this.user.permission < 3) {
        alert("You are not allowed to do this action");
      } else {
        this.show_alert_delete = true;
        this.alert_message = "Are you sure you want to delete this item?";
        this.delete_item = item;
      }
    },
    proceed_delete() {
      // console.log("delete");
      this.show_alert_delete = false;
      this.progress_dialog = true;
      this.delete_beneficiary(this.delete_item).then(() => {
        console.log("deleted");
        this.progress_dialog = false;
        this.set_notification({
          message: "Contract Beneficiary successfully deleted",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      });

      // });created
    },
    close_delete() {
      this.show_alert_delete = false;
    },
    confirm_add_beneficiary() {
      if (this.$refs.form.validate()) {
        this.show_alert = true;
        this.alert_message =
          "Are you sure you want to add " + this.form.name + " as beneficiary?";
      } else {
        this.set_notification({
          message: "Error: Please check your fields",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
    // open_modal() {
    //   switch (this.user.permission) {
    //     case "4":
    //       this.dialog = true;
    //       break;
    //     case "3":
    //       this.dialog = true;
    //       break;
    //     case "2":
    //       alert("You are not allowed to do this action");
    //       break;
    //     case "1":
    //       alert("You are not allowed to do this action");
    //       break;
    //     case "0":
    //       break;
    //   }
    //   this.dialog = true;
    // },

    close_dialog() {
      this.$emit("close_beneficiary_dialog");
    },
    proceed_add() {
      this.show_alert = false;
      // set form id to contract id
      this.form.contract_id = this.contract.id;
      this.progress_dialog = true;
      this.add_beneficiary(this.form).then(() => {
        this.$refs.form.reset();
        this.progress_dialog = false;
        this.set_notification({
          message: "You have successfully added a new beneficiary",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      });
    },
    close_add() {
      this.show_alert = false;
    },
  },
};
</script>

<style></style>
