<template>
  <v-container class="mt-15">
    <v-container class="fill-height mt-13" fluid>
      <v-row align="center" justify="center">
        <v-col cols="13" sm="8" md="8">
          <v-card class="elevation-12 rounded-l" max-width="">
            <v-row>
              <v-col class="primary darken-4 white--text">
                <v-container class="mt-5 mb-15">
                  <div class="d-flex justify-center">
                    <v-img
                      class=""
                      src="@/assets/mata_logo.png"
                      max-width="50%"
                    ></v-img>
                  </div>
                  <div class="text-center">
                    <v-row>
                      <v-col class="">
                        <h2
                          class="text-h4 font-weight-bold primary--text text--lighten-4"
                        >
                          MATA ONLINE PORTAL
                        </h2>
                        <p
                          class="font-weight-thin text-h6 primary--text text--lighten-3"
                        >
                          Mata Funeral Homes & Plan Inc.
                        </p>
                      </v-col>
                    </v-row>
                    <br />
                    <br />
                  </div>
                </v-container>
              </v-col>
              <v-col>
                <div class="mt-2 mb-11">
                  <v-btn
                    text
                    :color="user_signin ? 'grey' : 'blue'"
                    small
                    class="rounded-xl"
                    @click="user_signin = false"
                  >
                    <v-icon class="mr-2">mdi-account-outline</v-icon>Member
                    Login
                  </v-btn>
                  <v-btn
                    text
                    :color="user_signin ? 'blue' : 'grey'"
                    small
                    class="rounded-xl"
                    @click="user_signin = true"
                  >
                    <v-icon class="mr-2">mdi-account</v-icon>User Login
                  </v-btn>
                </div>
                <!-- USER SIGNIN -->
                <div class="mr-11 ml-5 text-center" v-if="user_signin">
                  <h2 class="blue--text mb-4">User Login</h2>

                  <v-form ref="form">
                    <v-text-field
                      class="rounded-xl"
                      prepend-icon="mdi-account-outline"
                      label="Username"
                      placeholder="Enter your username"
                      filled
                      v-model="form.username"
                    ></v-text-field>
                    <v-text-field
                      type="password"
                      class="rounded-xl"
                      prepend-icon="mdi-lock-outline"
                      label="Password"
                      placeholder="Enter your password"
                      filled
                      v-model="form.password"
                    ></v-text-field>
                  </v-form>
                  <v-btn
                    rounded
                    elevation="2"
                    color="primary darken-4"
                    class="mr-2"
                    @click="submit()"
                    :loading="loading"
                    >Login</v-btn
                  >

                  <v-btn rounded elevation="2" color="yellow" @click="reset"
                    >Reset</v-btn
                  >
                  <p class="caption primary--text mt-5">
                    Forgot your password?
                  </p>
                </div>
                <!-- MEMBER LOGIN -->
                <div class="mr-11 ml-5 text-center" v-else>
                  <h2 class="blue--text mb-4">Member Login</h2>

                  <v-form ref="form">
                    <v-text-field
                      class="rounded-xl"
                      prepend-icon="mdi-clipboard-list"
                      label="Contract Number"
                      placeholder="Enter your contract number"
                      filled
                      v-model="member_form.contract_no"
                    ></v-text-field>
                    <v-text-field
                      type="password"
                      class="rounded-xl"
                      prepend-icon="mdi-lock-outline"
                      label="Code"
                      placeholder="Enter your password"
                      filled
                      v-model="member_form.contract_id"
                    ></v-text-field>
                  </v-form>
                  <v-btn
                    rounded
                    elevation="2"
                    color="primary darken-4"
                    class="mr-2"
                    @click="submit_member()"
                    :loading="loading"
                    >Login</v-btn
                  >

                  <v-btn rounded elevation="2" color="yellow" @click="reset"
                    >Reset</v-btn
                  >

                  <p class="caption mt-9 grey--text">
                    Note: Please enter your contract number and code sent from
                    mata online portal text message.
                  </p>
                  <p class="caption primary--text mt-5"></p>
                </div>
              </v-col>
            </v-row>
          </v-card>
          <p class="caption grey--text text--lighten-0 mt-10 text-center">
            Powered by: JMBComputers IT Solutions | Version
            {{ packageJson.version }} |

            <br />
            <v-icon color="white" small class="mr-2">mdi-gmail</v-icon
            >janmichaelbesing873@gmail.com
          </p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import packageJson from "../../package.json";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      packageJson: packageJson,
      user_signin: false,
      form: {
        username: "",
        password: "",
      },
      member_form: {
        contract_id: "",
        contract_no: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      appVersion: "auth/appVersion",
    }),
  },
  methods: {
    ...mapActions({
      signin: "auth/signin",
      member_signin: "contract/member_signin",
      set_notification: "notification/set_notification",
    }),
    reset() {
      (this.form.username = ""), (this.form.password = "");
    },
    submit_member() {
      this.loading = true;
      this.member_signin(this.member_form)
        .then(() => {
          this.loading = false;
          this.$router.push(
            "/member/contract_profile/" +
              this.member_form.contract_no +
              "/" +
              this.member_form.contract_id
          );
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.loading = false;
            this.set_notification({
              message: "Username or Password Incorrect. Please try again.",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });
          } else {
            this.loading = false;
            this.set_notification({
              message: "Something went wrong. Please contact the administrator",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });
          }
        });
    },
    submit() {
      this.loading = true;
      this.signin(this.form)
        .then(() => {
          this.loading = false;
          this.$router.push("/contracts");
        })
        .catch((err) => {
          if (err.response.status == 401) {
            this.loading = false;
            this.set_notification({
              message: "Username or Password Incorrect. Please try again.",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });
          } else {
            this.set_notification({
              message: "Something went wrong. Please contact the administrator",
              color: "red",
              showing: true,
              icon: "mdi-alert",
            });
          }
        });
    },
  },
};
</script>

<style></style>
