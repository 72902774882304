<template>
  <div v-if="true">
    <v-btn class="rounded-xl" color="yellow" @click="open" small
      ><v-icon>mdi-magnify</v-icon>Search Payment</v-btn
    >
    <v-dialog v-model="dialog" width="700" persistent>
      <v-card>
        <v-sheet class="pa-5 white--text" color="blue">
          <h2>Search for a payment</h2>
        </v-sheet>
        <v-card-text>
          <v-text-field
            label="PR Number"
            v-model="pr_number"
            class="mt-2"
          ></v-text-field>
          <div v-if="table_ready">
            <h2 class="mb-2">Search Results</h2>
            <v-simple-table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Contract ID</th>
                  <th>Plan Holder</th>
                  <th>Payment Date</th>
                  <th>Amount Paid</th>
                  <th>Posted by</th>
                  <th>Pr Number</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="payment in found_payments" :key="payment.id">
                  <td>{{ payment.id }}</td>
                  <td>{{ payment.contract_id }}</td>
                  <td>{{ payment.contract.customer_name }}</td>
                  <td>{{ payment.payment_date }}</td>
                  <td>{{ payment.amount_paid }}</td>
                  <td>{{ payment.posted_by }}</td>
                  <td>{{ payment.pr_number }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <v-btn
            class="rounded-xl mr-2 white--text"
            @click="search"
            color="blue"
            :loading="button_loading"
            >Search</v-btn
          >
          <v-btn class="rounded-xl" @click="close">Close</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      hidden: false,
      dialog: false,
      found_payments: [],
      pr_number: "",
      table_ready: false,
      button_loading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({
      search_payment: "payment/search_payment",
    }),
    open() {
      switch (this.user.permission) {
        case "4":
          this.dialog = true;
          break;
        case "3":
          this.dialog = true;
          break;
        case "2":
          this.dialog = true;
          break;
        case "1":
          alert("You are not allowed to do this action");
          break;
        case "0":
          alert("You are not allowed to do this action");
      }
    },
    close() {
      this.dialog = false;
    },
    search() {
      this.button_loading = true;
      this.search_payment(this.pr_number).then((data) => {
        this.found_payments = data;
        // console.log(this.response.data);
        this.table_ready = true;
        this.button_loading = false;
      });
    },
  },
  created() {},
};
</script>
