import axios from "axios";
import moment from "moment";
export default {
  namespaced: true,
  state: {
    all_payments: null,
    payments: null,
    payment: null,
    total_number_of_payments: 0,
  },
  getters: {
    payments(state) {
      return state.payments;
    },
    payment(state) {
      return state.payment;
    },
    all_payments(state) {
      return state.all_payments;
    },
    total_number_of_payments(state) {
      return state.total_number_of_payments;
    },
  },
  mutations: {
    DELETE_PAYMENT(state, request_id) {
      let filtered = state.payment.filter((c) => c.id != request_id);
      state.payment = filtered;
    },
    // EDIT_PAYMENT(state, request) {
    //   state.payment.forEach((p) => {
    //     if (p.id == request.id) {
    //       p = request;
    //     }
    //   });
    // },
    SET_PAYMENTS(state, payments) {
      state.payments = payments;
    },
    SET_ALL_PAYMENTS(state, payments) {
      state.all_payments = payments;
    },
    SET_PAYMENT(state, payload) {
      var request = payload.x;
      var payment = payload.data;
      // set number of payment
      state.total_number_of_payments = payload.data.length;
      var contract_id = request.contract_id;
      // create an array of objects from installments number
      var modified_payments = [];

      var application_date = moment(request.date_created);
      var payment_date;
      var amount_paid;
      var pr_number;
      var posted_by;
      var payment_id;

      for (var i = 0; i < request.installments_number; i++) {
        // increment 1 month from date_created
        var b = application_date
          .clone()
          .add(i, "M")
          .format("MMM-YYYY")
          .toString();
        // check if payment[i] is out of index
        if (payment[i]) {
          payment_id = payment[i].id;
          payment_date = payment[i].payment_date;
          amount_paid = payment[i].amount_paid;
          pr_number = payment[i].pr_number;
          posted_by = payment[i].posted_by;
        } else {
          payment_id = null;
          payment_date = "unpaid";
          amount_paid = "n/a";
          pr_number = "n/a";
          posted_by = "n/a";
        }
        var x = {
          id: payment_id,
          contract_id: contract_id,
          installment_number: i + 1,
          payment_for_the_month_of: b,
          payment_date: payment_date,
          amount_paid: amount_paid,
          pr_number: pr_number,
          posted_by: posted_by,
        };
        // console.log(x);
        modified_payments.push(x);
      }
      state.payment = modified_payments;
    },
  },
  actions: {
    // get payments
    async get_all_payments({ commit }) {
      let response = await axios.get("/payments");
      commit("SET_ALL_PAYMENTS", response.data);
    },
    // delete payment
    async delete_payment({ dispatch, rootState }, request) {
      console.log(request);
      let response = await axios.delete("/payment/" + request.id);

      if (response.data == "0") {
        alert("ERROR: Please contact administrator");
      } else {
        // commit("DELETE_PAYMENT", request.id);
        var action = {
          user_id: rootState.auth.user.id,
          name: rootState.auth.user.first_name,
          action: "deleted",
          payment_id: request.id,
          date_time: new Date(),
        };
        dispatch("action/add_action", action, { root: true });
        dispatch("payment/get_payment", request.contract_id, { root: true });
      }
    },
    // edit payment
    async edit_payment({ dispatch, rootState }, request) {
      await axios.put("/payment/" + request.id, request);
      // commit("EDIT_PAYMENT", response.data);
      var action = {
        user_id: rootState.auth.user.id,
        name:
          rootState.auth.user.first_name + " " + rootState.auth.user.last_name,
        action: "edited payment",
        payment_id: request.id,
        date_time: new Date(),
      };
      dispatch("action/add_action", action, { root: true });
    },
    // add payment
    async add_payment({ dispatch, rootState }, request) {
      let response = await axios.post("/payment", request);

      var action = {
        user_id: rootState.auth.user.id,
        name:
          rootState.auth.user.first_name + " " + rootState.auth.user.last_name,
        action: "added payment",
        contract_id: request.contract_id,
        date_time: new Date(),
      };
      dispatch("action/add_action", action, { root: true });

      // update payment
      dispatch("payment/get_payment", request.contract_id, { root: true });

      return response.data;
    },
    // get payments
    async get_payments({ commit }, contract_id) {
      let response = await axios.get("payments/" + contract_id);
      commit("SET_PAYMENTS", response.data);
    },
    // get payment
    async get_payment({ commit, rootState }, id) {
      let response = await axios.get("payments/" + id);
      // NOTE: make sure that the payment array is ordered in ASC order payment_date
      var contract = rootState.contract.contract;
      // if(contract.mode_of_installments =='MONTHLY') var installments_no = 60;
      // else installments_no = contract.installment_number;
      var x = {
        date_created: contract.date_created,
        installments_number:
          contract.contract_status == "Plan Redated"
            ? contract.installments_number
            : 60,
        contract_id: id,
      };
      var data = response.data;
      console.log(data);
      commit("SET_PAYMENT", { data, x });
    },
    // search payment
    async search_payment(_, pr_number) {
      let response = await axios.get("search_payment/" + pr_number);
      return response.data;
    },
  },
};
