<template>
  <div>
    <v-btn small @click="open" class="rounded-xl white--text" color="blue">
      <v-icon class="pr-2">mdi-account-plus</v-icon>
      Add Contract
    </v-btn>
    <v-dialog max-width="1150" v-model="dialog" persistent>
      <v-sheet color="blue darken-2" class="pa-5">
        <v-row class="ml-1 mt-1">
          <v-col class="pr-10" cols="">
            <h2 class="display-1 white--text">Add New Contract</h2>
            <p class="grey--text text-lighten-3">
              Form for adding a new contract
            </p>
          </v-col>
        </v-row>
      </v-sheet>

      <v-card>
        <v-form ref="form">
          <v-card-text>
            <!-- FIRST ROW -->
            <div class="pt-5">
              <h1 class="grey--text ml-3">Contract Information</h1>
              <v-row class="pt-8">
                <!-- FIRST COL -->
                <v-col>
                  <!-- CONTRACT NUMBEr -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-file"
                    v-model="form.contract_no"
                    filled
                    color="blue-grey lighten-2"
                    label="Contract Number"
                  />
                  <!-- DATE CREATED -->
                  <v-dialog
                    ref="application_dialog"
                    v-model="date_created_modal1"
                    :return-value.sync="form.date_created"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="rules.required"
                        :class="text_field_padding"
                        filled
                        v-model="form.date_created"
                        label="Application Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.date_created" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="date_created_modal1 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.application_dialog.save(form.date_created)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <!-- END DATE CREATED -->

                  <!-- PLAN TYPE -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.plan_type"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-flower-outline"
                    :items="plan_type"
                    filled
                    label="Plan Type"
                  ></v-select>
                </v-col>
                <!-- 2nd COL -->
                <v-col>
                  <!-- CONTRACT PRICE -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.contract_price"
                    filled
                    color="blue-grey lighten-2"
                    label="Contract Price"
                    prepend-inner-icon="mdi-currency-usd"
                  >
                  </v-text-field>
                  <!-- BASE VALUE -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.base_value"
                    filled
                    color="blue-grey lighten-2"
                    label="Base Value"
                    prepend-inner-icon="mdi-scale-balance"
                  >
                  </v-text-field>
                  <!-- INSURABLE -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.insurable"
                    :class="text_field_padding"
                    prepend-inner-icon=""
                    :items="insurable"
                    item-text="text"
                    item-value="val"
                    filled
                    label="Insurable"
                  >
                  </v-select>
                </v-col>
                <!-- 3rd COL -->
                <v-col>
                  <!-- MODE OF PAYMENT -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.mode_of_payment"
                    :class="text_field_padding"
                    prepend-inner-icon=""
                    :items="mode_of_payment"
                    item-text="text"
                    item-value="val"
                    filled
                    label="Mode of Payment"
                  ></v-select>
                  <!-- MODE OF INSTALLMENT -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.mode_of_installments"
                    :class="text_field_padding"
                    prepend-icon=""
                    :items="mode_of_installment"
                    item-text="text"
                    item-value="val"
                    filled
                    label="Mode of Installment"
                  ></v-select>
                  <!-- INITIAL PAYMENT -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.initial_payment"
                    filled
                    color="blue-grey lighten-2"
                    label="Initial Payment"
                    prepend-inner-icon="mdi-currency-usd"
                  >
                  </v-text-field>
                </v-col>
                <!-- 4th COL -->
                <v-col>
                  <!-- INSTALLMENT VALUE -->
                  <v-text-field
                    :rules="rules.required"
                    class="text_field"
                    width="150"
                    :class="text_field_padding"
                    v-model="form.installment_value"
                    filled
                    color="blue-grey lighten-2"
                    label="Installment Value"
                    prepend-inner-icon="mdi-currency-usd"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- SECOND ROW -->
            <!-- <br />
          <v-divider max-width="600" ></v-divider> -->
            <div class="pt-">
              <h1 class="grey--text ml-3">Personal Information</h1>
              <v-row class="pt-8">
                <!-- 1st COL -->
                <v-col cols="3">
                  <!-- CUSTOMER NAME -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_name"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Name"
                    prepend-inner-icon="mdi-account"
                  ></v-text-field>
                  <!-- CUSTOMER ADDRESS -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_address"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Address"
                    prepend-inner-icon="mdi-map-marker"
                  >
                  </v-text-field>
                  <!-- CUSTOMER BUSINESS ADDRESS -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_business_address"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Business Address"
                    prepend-inner-icon="mdi-map-marker"
                  ></v-text-field>
                  <!-- CUSTOMER BIRTHDATE -->
                  <custom-date-picker
                    icon="mdi-calendar"
                    label="Customer Birthdate"
                    placeholder="YYYY-MM-DD"
                    @set_date="add_customer_birthdate"
                  />
                  <!-- <v-dialog
                    ref="customer_dialog"
                    v-model="customer_birthdate_dialog"
                    :return-value.sync="form.customer_birthdate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="rules.required"
                        :class="text_field_padding"
                        filled
                        v-model="form.customer_birthdate"
                        label="Customer Birthdate"
                        prepend-inner-icon="mdi-cake"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.customer_birthdate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="customer_birthdate_dialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          $refs.customer_dialog.save(form.customer_birthdate)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog> -->
                  <!-- END CUSTOMER BIRTHDATE -->
                </v-col>
                <!-- 2nd COL -->
                <v-col cols="3">
                  <!-- CIVIL STATUS -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.customer_civil_status"
                    :class="text_field_padding"
                    prepend-icon=""
                    :items="civil_status"
                    filled
                    label="Civil Status"
                  ></v-select>
                  <!-- GENDER -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.customer_gender"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-gender-male-female"
                    :items="gender"
                    item-text="text"
                    item-value="val"
                    filled
                    label="Gender"
                  ></v-select>
                  <!-- RELIGION -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_religion"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Religion:"
                    prepend-inner-icon="mdi-church"
                  >
                  </v-text-field>

                  <!-- CUSTOMER ANNUAL INCOME -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_annual_income"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Annual Income"
                    prepend-inner-icon="mdi-currency-usd"
                  ></v-text-field>
                </v-col>
                <!-- 3rd COL -->
                <v-col cols="3">
                  <!-- CUSTOMER TIN -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_tin"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer TIN"
                    prepend-inner-icon="mdi-barcode"
                  ></v-text-field>
                  <!-- CUSTOMER SSS -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_sss"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer SSS"
                    prepend-inner-icon="mdi-barcode"
                  ></v-text-field>
                  <!-- CUSTOMER TEL -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_tel"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Telephone Number"
                    prepend-inner-icon="mdi-phone"
                  >
                  </v-text-field>
                  <!-- CUSTOMER MOBILE -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_mobile"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Mobile Number"
                    prepend-inner-icon="mdi-cellphone-basic"
                  ></v-text-field>
                </v-col>
                <!-- 4th COL  -->
                <v-col cols="">
                  <!-- CUSTOMER EMAIL -->

                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_email"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Email"
                    prepend-inner-icon="mdi-email"
                  ></v-text-field>
                  <!-- CUSTOMER OCCUPATION -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.customer_occupation"
                    filled
                    color="blue-grey lighten-2"
                    label="Customer Occupation"
                    prepend-inner-icon="mdi-briefcase-outline"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- THIRD ROW -->
            <div class="pt-">
              <h1 class="grey--text ml-3">Payor's Information</h1>
              <v-row class="pt-8">
                <!-- 1st COL -->
                <v-col>
                  <!-- SAME PAYOR? -->
                  <v-select
                    @change="same_payor_action"
                    v-model="form.same_payor"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-help-circle-outline"
                    :items="same_payor"
                    item-text="text"
                    item-value="val"
                    filled
                    label="Same as Principal Member"
                  ></v-select>
                  <!-- PAYOR RELATION -->
                  <v-text-field
                    :disabled="payor_disabled"
                    :class="text_field_padding"
                    v-model="form.payor_relation"
                    filled
                    color="blue-grey lighten-2"
                    label="Payor Relation"
                    prepend-inner-icon=""
                  ></v-text-field>
                  <!-- PAYOR NAME -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.payor_name"
                    filled
                    color="blue-grey lighten-2"
                    label="Payor Name"
                    prepend-inner-icon="mdi-account-outline"
                  >
                  </v-text-field>
                </v-col>
                <!-- 2nd COL -->
                <v-col>
                  <!-- PAYOR ADDRESS -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.payor_address"
                    filled
                    color="blue-grey lighten-2"
                    label="Payor Address"
                    prepend-inner-icon="mdi-map-marker"
                  >
                  </v-text-field>
                  <!-- PAYOR BIRTHDATE -->

                  <v-dialog
                    ref="payor_dialog"
                    v-model="payor_birthdate_dialog"
                    :return-value.sync="form.payor_birthdate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="rules.required"
                        :class="text_field_padding"
                        filled
                        v-model="form.payor_birthdate"
                        label="Payor Birthdate"
                        prepend-inner-icon="mdi-cake"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.payor_birthdate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="customer_payor_dialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.payor_dialog.save(form.payor_birthdate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <!-- PAYOR CIVIL STATUS -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.payor_civil_status"
                    :class="text_field_padding"
                    prepend-icon=""
                    :items="civil_status"
                    filled
                    label="Payor Civil Status"
                  ></v-select>
                </v-col>
                <!-- 3rd COL -->
                <v-col>
                  <!-- PAYOR GENDER -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.payor_gender"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-gender-male-female"
                    :items="gender"
                    item-text="text"
                    item-value="val"
                    filled
                    label="Payor Gender"
                  ></v-select>
                  <!-- PAYOR TEL -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.payor_tel"
                    filled
                    color="blue-grey lighten-2"
                    label="Payor Telephone Number"
                    prepend-inner-icon="mdi-phone"
                  >
                  </v-text-field>
                  <!-- PAYOR MOBILE -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.payor_mobile"
                    filled
                    color="blue-grey lighten-2"
                    label="Payor Mobile Number"
                    prepend-inner-icon="mdi-cellphone-basic"
                  >
                  </v-text-field>
                </v-col>
                <!-- 4th COL -->
                <v-col>
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.payor_email"
                    filled
                    color="blue-grey lighten-2"
                    label="Payor Email"
                    prepend-inner-icon="mdi-email"
                  ></v-text-field>
                </v-col>
                <!-- PAYOR EMAIL -->
              </v-row>
            </div>
            <!-- FOURTH ROW -->
            <div class="pt-">
              <v-row class="pt-8">
                <!-- 1st COL -->
                <v-col>
                  <!-- SIGNED DATE -->
                  <v-dialog
                    ref="signed_dialog"
                    v-model="signed_date_dialog"
                    :return-value.sync="form.signed_date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="rules.required"
                        :class="text_field_padding"
                        filled
                        v-model="form.signed_date"
                        label="Signed Date"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="form.signed_date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="signed_date_dialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.signed_dialog.save(form.signed_date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <!-- END SIGNED DATE -->
                  <!-- ENCODER -->
                  <v-text-field
                    ref="encoder"
                    disabled
                    :class="text_field_padding"
                    :value="this.user.first_name + ' ' + this.user.last_name"
                    filled
                    color="blue-grey lighten-2"
                    label="Encoded by:"
                    prepend-inner-icon="mdi-typewriter"
                  ></v-text-field>
                  <!-- ENCODED DATE -->
                  <v-text-field
                    disabled
                    :class="text_field_padding"
                    v-model="form.encoded_date"
                    filled
                    color="blue-grey lighten-2"
                    label="Encoded Date:"
                    prepend-inner-icon="mdi-calendar"
                  ></v-text-field>
                </v-col>
                <!-- 2nd COL -->
                <v-col>
                  <!-- SIGNED ADDRESS -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.signed_address"
                    filled
                    color="blue-grey lighten-2"
                    label="Signed Address:"
                    prepend-inner-icon=""
                  ></v-text-field>
                  <!-- COMMISSION Percentage -->
                  <v-text-field
                    :rules="rules.required"
                    :class="text_field_padding"
                    v-model="form.agent_commission_percentage"
                    filled
                    color="blue-grey lighten-2"
                    label="Commission Percentage:"
                    prepend-inner-icon=""
                  ></v-text-field>
                  <!-- BUSINESS MANAGER -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.business_manager"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-account-tie"
                    :items="business_manager"
                    filled
                    label="Business Manager"
                  >
                  </v-select>
                </v-col>
                <!-- 3rd COL -->
                <v-col>
                  <!-- AGENTS -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.agent_id"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-account-group"
                    :items="formatted_agents"
                    item-text="full_name"
                    item-value="id"
                    filled
                    label="Agent"
                  ></v-select>
                  <!-- BRANCHES -->
                  <v-select
                    :rules="rules.required"
                    v-model="form.branch_id"
                    :class="text_field_padding"
                    prepend-inner-icon="mdi-domain"
                    :items="branches"
                    item-text="branch_name"
                    item-value="id"
                    filled
                    label="Branch"
                  ></v-select>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-divider max-width="600"></v-divider>
          <v-card-actions class="text-center">
            <p class="lighten-2 caption mt-3 pl-3">
              <v-icon color="" class="pr-2">mdi-information</v-icon>
              Note: Please review your fields before submitting.
            </p>
            <v-spacer></v-spacer>
            <v-btn
              class="mr-2 primary --text rounded-xl"
              elevation=""
              color=""
              @click="add"
            >
              Submit</v-btn
            >

            <v-btn
              class="mr-2 rounded-xl"
              elevation=""
              color="yellow black--text"
              @click="revert"
            >
              Reset Fields</v-btn
            >

            <v-btn
              class="rounded-xl"
              elevation=""
              color="grey darken-2 white--text"
              @click="dialog = false"
              >Cancel
            </v-btn>
          </v-card-actions>
          <div v-if="show_alert">
            <!-- CONFIRM NOTIFICATION -->
            <confirm-notification
              icon="mdi-alert-outline"
              class="alert"
              type="warning"
              :message="this.alert_message"
              @confirm-event="proceed"
              @cancel-event="close_confirm"
            />
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import ConfirmNotification from "@/components/ConfirmNotification.vue";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import ProgressLinear from "./ProgressLinear.vue";
import CustomDatePicker from "./CustomDatePicker.vue";

export default {
  components: {
    ConfirmNotification,
    ProgressLinear,
    CustomDatePicker,
  },
  data() {
    return {
      progress_dialog: false,
      rules: {
        number_rule: [(v) => v.length >= 3 || "Minimum length is 3 characters"],
        age_rule: [(v) => v.length >= 3 || "Minimum length is 1 character"],
        file_rule: [(v) => !!v || "File is required"],
        required: [(value) => !!value || "Field is required"],
      },

      show_alert: false,
      will_confirm: false,
      alert_message: "",
      alert_item_id: "",

      payor_disabled: false,
      signed_date_dialog: false,
      payor_birthdate_dialog: false,
      customer_birthdate_dialog: false,
      same_payor: [
        { val: "1", text: "Yes" },
        { val: "0", text: "No" },
      ],

      business_manager: ["JULIUS CAESAR MATA", "LOLITA MATA", "HENRY MATA"],
      gender: ["Male", "Female"],
      civil_status: ["SINGLE", "MARRIED", "WIDOW/ER", "SEPARATED"],
      mode_of_payment: ["SPOT CASH", "5 YEARS INSTALLMENT"],
      mode_of_installment: ["MONTHLY", "QUARTERLY", "SEMI-ANNUAL", "ANNUAL"],
      insurable: [
        { val: "1", text: "Yes" },
        { val: "0", text: "No" },
      ],
      plan_type: [
        "GENESIS",
        "NUMBERS",
        "PROVERBS",
        "EXODUS",
        "DEUTERONOMY",
        "ECCLESIASTES",
        "LEVITICUS",
        "PSALMS",
      ],
      text_field_padding: "pr-2 pl-2 rounded-xl",
      dialog: false,
      name: "",
      color: "grey",
      input_text_color: "--text",
      title: "",
      date_created_modal1: false,
      date: "",
      form: {
        contract_no: "",
        date_created: "",
        plan_type: "",
        contract_price: "",
        base_value: "",
        insurable: "",
        mode_of_payment: "",
        mode_of_installments: "",
        installment_value: "",
        initial_payment: "",
        customer_name: "",
        customer_address: "",
        customer_business_address: "n/a",
        customer_birthdate: "",
        customer_civil_status: "",
        customer_gender: "",
        customer_occupation: "n/a",
        customer_annual_income: "",
        customer_tin: "n/a",
        customer_sss: "n/a",
        customer_tel: "###-####-####",
        customer_mobile: "",
        customer_email: "n/a",
        customer_religion: "n/a",
        same_payor: "",
        payor_relation: "",
        payor_name: "",
        payor_address: "",
        payor_birthdate: "",
        payor_civil_status: "",
        payor_gender: "",
        payor_tel: "###-####-###",
        payor_mobile: "",
        payor_email: "n/a",
        signed_date: "",
        signed_address: "",

        business_manager: "",
        encoder: "",
        encoded_date: "",
        agent_id: "",
        agent_commission_percentage: "50",
        branch_id: "",
        contract_status: "Pending",
        updated_by: "",
      },
    };
  },

  computed: {
    ...mapGetters({
      agents: "agent/agents",
      user: "auth/user",
      branches: "branch/branches",
      quotes: "quote/quotes",
      contracts: "contract/contracts",
    }),

    formatted_agents() {
      if (this.agents) {
        var arr = [];
        this.agents.map((x) => {
          var new_a = {
            full_name: x.surname + ", " + x.name,
            id: x.id,
          };
          arr.push(new_a);
        });
        return arr;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      add_contract: "contract/add_contract",
      set_notification: "notification/set_notification",
      get_agents: "agent/get_agents",
      get_branches: "branch/get_branches",
    }),

    close_confirm() {
      this.show_alert = false;
    },
    add_customer_birthdate(date) {
      this.form.customer_birthdate = date.date;
      console.log("customer birthdate:" + this.form.customer_birthdate);
    },
    async proceed() {
      this.progress_dialog = true;
      // set updated by the user log in
      this.form.updated_by = this.user.last_name + ", " + this.user.first_name;
      let response = await this.add_contract(this.form);
      if (!response) {
        this.set_notification({
          message: "Please try again",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
        this.show_alert = false;
        this.progress_dialog = false;
      } else {
        this.set_notification({
          message: "You have successfully added a contract",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.show_alert = false;
        this.progress_dialog = false;
      }
    },
    revert() {
      this.$refs.form.reset();
    },
    add() {
      this.form.encoder = this.user.first_name + " " + this.user.last_name;
      if (this.$refs.form.validate()) {
        this.alert_message =
          "Have you already reviewed your inputs? Click yes to proceed";
        this.show_alert = true;
      } else {
        this.set_notification({
          message: "Some fields are missing. Please try again",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
    open() {
      switch (this.user.permission) {
        case "4":
          this.progress_dialog = true;
          if (!this.agents || !this.branches) {
            this.get_agents()
              .then(() => {
                this.get_branches().then(() => {
                  this.progress_dialog = false;
                  this.dialog = true;
                  this.form.encoder = this.user.name;
                  this.form.encoded_date = moment().toString();
                });
              })
              .catch(() => {
                this.set_notification({
                  message:
                    "Connection timeout. Please check your internet connection or refresh the page to retry.",
                  color: "red",
                  showing: true,
                  icon: "mdi-alert",
                });
                this.progress_dialog = false;
              });
          } else {
            this.progress_dialog = false;
            this.dialog = true;
            this.form.encoder = this.user.name;
            this.form.encoded_date = moment().toString();
          }

          break;
        case "3":
          alert("You are not allowed to do this action");
          break;
        case "2":
          alert("You are not allowed to do this action");
          break;
        case "1":
          alert("You are not allowed to do this action");
          break;
        case "0":
          this.progress_dialog = true;
          if (!this.agents || !this.branches) {
            this.get_agents().then(() => {
              this.get_branches().then(() => {
                this.progress_dialog = false;
                this.dialog = true;
                this.form.encoder = this.user.name;
                this.form.encoded_date = moment().toString();
              });
            });
          } else {
            this.progress_dialog = false;
            this.dialog = true;
            this.form.encoder = this.user.name;
            this.form.encoded_date = moment().toString();
          }

          break;
      }
    },
    same_payor_action(v) {
      if (v === "1") {
        this.payor_disabled = true;
        this.form.payor_name = this.form.customer_name;
        this.form.payor_address = this.form.customer_address;
        this.form.payor_birthdate = this.form.customer_birthdate;
        this.form.payor_civil_status = this.form.customer_civil_status;
        this.form.payor_gender = this.form.customer_gender;
        this.form.payor_tel = this.form.customer_tel;
        this.form.payor_mobile = this.form.customer_mobile;
        this.form.payor_email = this.form.customer_email;
      } else {
        this.payor_disabled = false;
        this.form.payor_name = "";
        this.form.payor_address = "";
        this.form.payor_birthdate = "";
        this.form.payor_civil_status = "";
        this.form.payor_gender = "";
        this.form.payor_tel = "";
        this.form.payor_mobile = "";
        this.form.payor_email = "";
      }
    },
  },

  // console.log(moment().toString());
};
</script>

<style scoped>
.v-text-field {
  width: "100px";
}
</style>
