<template>
  <div>
    <div class="mt-15">
      <v-img src="@/assets/mata_logo.png" class="mx-auto" width="200"></v-img>
    </div>
    <div class="">
      <h1 class="text-center blue--text display-2 font-weight-bold">
        Mata Online Portal
      </h1>
      <p class="text-center grey--text">
        "Giving you the best service to your loved ones"
      </p>
      <div style="width: 500px" class="mx-auto">
        <v-progress-linear
          indeterminate
          color="blue"
          max-width="500"
        ></v-progress-linear>
      </div>
      <p class="text-center grey--text mt-5">The page is loading...</p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
