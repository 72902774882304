import axios from "axios";
export default {
  namespaced: true,
  state: {
    member_contract:null,
    member_payments:null
  },
  getters: {
    member_contract(state) {
      return state.member_contract;
    },
    member_payments(state) {
        return state.member_payments;
      },
  },
  mutations: {
    SET_CONTRACT(state, contract) {
      state.member_contract = contract;
    },
    SET_PAYMENTS(state,payments){
        state.member_payments= payments;
    },
    CLEAR_VUEX(state){
      state.member_contract = null;
      state.member_payments = null;
    }
  },
  actions: {
    // get agents
    async get_member_contract({ commit }, request) {
      let response = await axios.get(
        "/member_contract/" + request.contract_no + "/" + request.contract_id
      );
      console.log("triggered");
      commit("SET_CONTRACT", response.data);
    },
    async get_member_payments({commit},contract_id){
        let response = await axios.get("/member_payments/"+contract_id);
        commit("SET_PAYMENTS",response.data);
    },
    clear_vuex({commit}){
      commit("CLEAR_VUEX");
      console.log("clear vuex");
    }
  },
};
