<template>
  <v-container>
    <div>
      <div v-if="!loading">
        <v-card class="pt-7">
          <v-row>
            <v-col class="ml-5">
              <div class="d-flex justify-first">
                <payment-modal class="mr-2" />
                <add-contract-modal class="mr-2" />
                <v-btn
                  small
                  @click="to_excel"
                  class="rounded-xl white--text"
                  color="green"
                >
                  <v-icon class="mr-2">mdi-microsoft-excel</v-icon>To Excel
                </v-btn>
                <paid-contracts-modal />
              </div>
            </v-col>
          </v-row>
          <v-row class="pr-5 pl-5">
            <v-col cols="5" class="d-flex justify-first">
              <v-select
                v-model="selected_header"
                :items="sortable_headers"
                item-text="header_name"
                item-value="value"
                label="Search for"
                @change="search_for"
              ></v-select>

              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                max-width=""
                placeholder="Enter a value..."
              >
              </v-text-field>
            </v-col>
            <v-col cols="">
              <FromTo />
            </v-col>
          </v-row>
          <v-data-table
            v-if="contracts"
            dense
            sort-by="created_at"
            :headers="contract_headers"
            :items="contracts"
            multi-sort
            :search="search"
            :options="options"
          >
            <!-- view profile -->
            <template v-slot:[`item.profile`]="{ item }">
              <div class="text-center">
                <v-icon class="mr-2" @click="profile(item)" color="primary">
                  mdi-account-circle
                </v-icon>
                <span class="caption primary--text">
                  <strong>Contract</strong>
                </span>
              </div>
            </template>
            <!-- view payments -->
            <template v-slot:[`item.payment`]="{ item }">
              <div class="text-center">
                <v-icon class="mr-2" color="yellow en-2" @click="payment(item)">
                  mdi-cash-multiple
                </v-icon>
                <span class="caption yellow--text text--en-2">
                  <strong>Payments</strong>
                </span>
              </div>
            </template>
            <!-- delete contract -->
            <!-- <template v-slot:[`item.delete`]="{ item }">
              <div class="text-center">
                <v-icon class="mr-2" color="red" @click="delete_contract(item)">
                  mdi-delete
                </v-icon>
                <span class="caption red--text">
                  <strong>Delete</strong>
                </span>
              </div>
            </template> -->
            <!-- Insurable -->
            <template v-slot:[`item.insurable`]="{ item }">
              <div class="text-center">
                <span class="success--text" v-if="item.insurable == 1">
                  Yes
                </span>
                <span class="primary--text" v-else> No </span>
              </div>
            </template>
            <!-- Account Status -->
            <!-- <template v-slot:[`item.contract_status`]="{ item }">
              <div class="text-center">
                <span class="success--text" v-if="item.contract_status == 'Active'">
                  Active
                </span>
                <span class="warning--text" v-else>Pending </span>
              </div>
            </template> -->
            <!-- Agent -->
            <template v-slot:[`item.agent`]="{ item }">
              <div class="text-center">
                <span> {{ item.agent.surname }}, {{ item.agent.name }} </span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </div>
      <the-loading-page v-if="loading" />
      <!-- confirm notification -->
      <div v-if="show_alert">
        <confirm-notification
          icon="mdi-alert-outline"
          class="alert"
          type="warning"
          :message="this.alert_message"
          @confirm-event="proceed_deletion"
          @cancel-event="close"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import "chartkick/chart.js";
import { mapGetters, mapActions } from "vuex";
import FromTo from "@/components/FromTo.vue";
import AddContractModal from "@/components/AddContractModal.vue";
import ConfirmNotification from "@/components/ConfirmNotification.vue";

import PaymentModal from "@/components/PaymentsModal.vue";
import TheLoadingPage from "../components/TheLoadingPage.vue";
import PaidContractsModal from "../components/PaidContractsModal.vue";

export default {
  components: {
    FromTo,
    AddContractModal,
    ConfirmNotification,

    PaymentModal,
    TheLoadingPage,
    PaidContractsModal,
  },
  data() {
    return {
      loading: false,

      progress_dialog: false,
      selected_header: null,
      show_alert: false,
      will_confirm: false,
      alert_message: "",
      alert_item_id: "",
      search: "",
      sortable_headers: [
        { header_name: "Id", value: "id" },
        { header_name: "Contract Number", value: "contract_no" },
        { header_name: "Customer Name", value: "customer_name" },
        { header_name: "Plan Type", value: "plan_type" },
        { header_name: "Agent", value: "agent" },
        { header_name: "Insurable", value: "insurable" },
        {
          header_name: "Installment Value",
          value: "installment_value",
        },
        { header_name: "Date Created", value: "date_created" },
        // { header_name: "Contract Status", value: "contract_status" },
      ],
      contract_headers: [
        { text: "", value: "profile" },
        { text: "", value: "payment" },
        // { text: "", value: "delete" },
        { text: "ID", value: "id", filterable: false },
        {
          text: "Contract Number",
          value: "contract_no",
          filterable: false,
        },
        {
          text: "Customer Name",
          value: "customer_name",
          filterable: false,
        },
        {
          text: "Plan Type",
          value: "plan_type",
          filterable: false,
        },
        {
          text: "Agent",
          value: "agent",
          filterable: false,
        },
        {
          text: "Insurable",
          value: "insurable",
          filterable: false,
          width: "100",
        },
        {
          text: "Installment Value",
          value: "installment_value",
          filterable: false,
        },
        {
          text: "Date Created",
          value: "date_created",
          filterable: false,
        },
      ],
      options: {
        itemsPerPage: 15,
      },
    };
  },
  computed: {
    ...mapGetters({
      contracts: "contract/contracts",
      contract_summary: "contract/contract_summary",
      token: "auth/token",
      default_server_url: "auth/default_server_url",
      user: "auth/user",
    }),
  },

  created() {
    // this.progress_dialog = !this.progress_dialog;
    if (!this.contracts) {
      this.loading = true;
      this.get_unpaid_contracts()
        .then(() => {
          this.loading = false;
          this.progress_dialog = !this.progress_dialog;
        })
        .catch(() => {
          this.set_notification({
            message:
              "Connection timeout. Please check your internet connection or refresh the page to retry.",
            color: "red",
            showing: true,
            icon: "mdi-alert",
          });
          this.loading = false;
        });
    } else {
      this.loading = false;
      this.progress_dialog = !this.progress_dialog;
    }
  },

  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      del: "contract/delete_contract",
      on_page_reload: "auth/on_page_reload",
      get_unpaid_contracts: "contract/get_unpaid_contracts",
    }),
    search_for() {
      this.contract_headers.forEach((c) => {
        if (
          (c.value == "profile") | (c.value == "payment") ||
          c.value == "delete"
        ) {
          console.log("no action");
        } else {
          if (this.selected_header == c.value) {
            console.log("found");
            c.filterable = true;
          } else {
            c.filterable = false;
          }
        }
      });
      console.log(this.contract_headers);
    },
    payment(item) {
      switch (this.user.permission) {
        case "5":
          this.$router.push("/payment/" + item.id);
          break;
        case "4":
          this.$router.push("/payment/" + item.id);
          break;
        case "3":
          this.$router.push("/payment/" + item.id);
          break;
        case "2":
          this.$router.push("/payment/" + item.id);
          break;
        case "1":
          this.$router.push("/payment/" + item.id);
          break;
        case "0":
          break;
      }
    },

    profile(item) {
      if (this.user.pemission == 0) {
        this.set_notification({
          message: "You are not allowed to do this action!",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      } else {
        this.$router.push("profile/" + item.id);
      }
    },
    close() {
      this.show_alert = false;
    },

    async proceed_deletion() {
      this.show_alert = false;
      this.progress_dialog = true;
      this.del(this.alert_item_id).then(() => {
        this.progress_dialog = false;
      });
    },
    to_excel() {
      var url = this.default_server_url + "export_to_excel/" + this.token;
      window.open(url);
    },
  },
};
</script>

<style></style>
