<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card>
      <v-sheet color="blue darken-2" class="pa-5 white--text">
        <h2>Edit Beneficiary</h2>
      </v-sheet>
      <v-card-text>
        <v-form ref="form" class="mt-6">
          <v-text-field
            ref="name"
            class="rounded-xl"
            filled
            v-model="item.name"
            label="Beneficiary Name"
            prepend-icon="mdi-account-outline"
          ></v-text-field>
          <v-text-field
            ref="birth_date"
            class="rounded-xl"
            filled
            v-model="item.birth_date"
            label="Beneficiary Birthdate"
            prepend-icon="mdi-cake"
          ></v-text-field>
          <v-text-field
            ref="relationship"
            class="rounded-xl"
            filled
            v-model="item.relationship"
            label="Relationship"
            prepend-icon="mdi-heart"
          ></v-text-field>
          <v-text-field
            ref="address"
            class="rounded-xl"
            filled
            v-model="item.address"
            label="Beneficiary Address"
            prepend-icon="mdi-map-marker"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="rounded-xl" @click="submit_edit"
          >Submit</v-btn
        >
        <v-btn color="" class="rounded-xl" @click="close_modal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="proceed_edit"
        @cancel-event="close"
      />
    </div>
    <progress-linear :dialog="progress_dialog" />
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmNotification from "./ConfirmNotification.vue";
import ProgressLinear from "./ProgressLinear.vue";
export default {
  components: { ConfirmNotification, ProgressLinear },
  props: ["item"],
  data() {
    return {
      progress_dialog: false,
      dialog: true,
      show_alert: false,
      alert_message: "Are you sure you want to edit this beneficiary?",
    };
  },
  methods: {
    ...mapActions({
      edit_beneficiary: "contract/edit_beneficiary",
      set_notification: "notification/set_notification",
    }),

    submit_edit() {
      this.show_alert = true;
    },
    close_modal() {
      this.$emit("close_edit_beneficiary_modal");
    },
    close() {
      this.show_alert = false;
    },
    proceed_edit() {
      this.show_alert = false;
      this.progress_dialog = true;
      this.edit_beneficiary(this.item).then(() => {
        this.set_notification({
          message: "You have successfully edited an item",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.progress_dialog = false;
      });
    },
  },
};
</script>

<style></style>
