<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="600">
      <v-card>
        <v-sheet color="blue darken-2">
          <div class="pa-10">
            <h2 class="white--text display-1">Edit Agent</h2>
          </div>
        </v-sheet>
        <v-card-text>
          <v-form ref="form" class="mt-5">
            <v-text-field
              label="First Name"
              prepend-icon="mdi-account"
              v-model="item.name"
              :rules="rules.number_rule"
            ></v-text-field>
            <v-text-field
              label="Last Name"
              prepend-icon="mdi-account"
              v-model="item.surname"
              :rules="rules.number_rule"
            ></v-text-field>
            <v-text-field
              label="Phone Number"
              prepend-icon="mdi-phone"
              v-model="item.phone_number"
              :rules="rules.number_rule"
            ></v-text-field>
            <v-select
              v-model="item.branch_id"
              prepend-icon="mdi-domain"
              item-text="branch_name"
              item-value="id"
              :items="branch"
              class="rounded-xl"
              label="Branch"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex-row justify-end">
          <v-btn @click="edit">Edit</v-btn>
          <v-btn @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <progress-linear :dialog="progress_dialog" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  components: { ProgressLinear },
  props: ["item"],
  data() {
    return {
      rules: {
        number_rule: [(v) => v.length >= 1 || "This field is required*"],
        age_rule: [(v) => v.length >= 1 || "Minimum length is 1 character"],
      },
      dialog: true,
      progress_dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      branches: "branch/branches",
    }),
    branch() {
      if (this.branches) {
        var arr = [];
        this.branches.forEach((b) => {
          var x = {
            branch_name: b.branch_name,
            id: b.id,
          };
          arr.push(x);
          console.log(arr);
        });
        return arr;
      } else {
        console.log("no branches");
        return null;
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      edit_agent: "agent/edit_agent",
    }),
    open() {
      this.dialog = true;
    },
    close() {
      this.$emit("close_edit_agent_dialog");
    },
    edit() {
      if (this.$refs.form.validate()) {
        this.progress_dialog = true;
        delete this.item.branch;
        delete this.item.contract_filed;
        console.log(this.item);
        this.edit_agent(this.item).then(() => {
          this.progress_dialog = false;
          this.set_notification({
            message: "You have successfully edited a new agent",
            color: "success",
            showing: true,
            icon: "mdi-check",
          });
        });
      } else {
        this.set_notification({
          message: "Please check all fields",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
  },
};
</script>

<style></style>
