import axios from "axios";

export default {
  namespaced: true,
  state: {
    contracts_consolidation: null,
    contracts: null,
    contract: null,
    contract_summary: null,
    archived_contracts: null,
    contract_with_payments: null,
    beneficiaries: null,
    paid_contracts: null,
  },
  getters: {
    paid_contracts(state) {
      return state.paid_contracts;
    },
    contracts_consolidation(state) {
      return state.contracts_consolidation;
    },
    contracts(state) {
      return state.contracts;
    },
    contract(state) {
      return state.contract;
    },
    contract_summary(state) {
      return state.contract_summary;
    },
    archived(state) {
      return state.archived_contracts;
    },
    contract_with_payments(state) {
      return state.contract_with_payments;
    },
    beneficiaries(state) {
      return state.beneficiaries;
    },
  },
  mutations: {
    SET_PAID_CONTRACTS(state, paid_contracts) {
      state.paid_contracts = paid_contracts;
    },
    MEMBER_SIGNOUT(state) {
      state.contract_with_payments = null;
    },
    SET_CONTRACT_WITH_PAYMENTS(state, contract_with_payments) {
      state.contract_with_payments = contract_with_payments;
    },
    SET_ARCHIVED_CONTRACTS(state, archived_contracts) {
      state.archived_contracts = archived_contracts;
    },
    SET_CONTRACT(state, response) {
      state.contract = response;
    },
    SET_CONTRACTS_CONSOLIDATION(state, contracts_consolidation) {
      state.contracts_consolidation = contracts_consolidation;
    },
    SET_CONTRACTS(state, contracts) {
      state.contracts = contracts;
    },
    DELETE_CONTRACT(state, request_id) {
      let filtered = state.contracts.filter((c) => c.id != request_id);
      state.contracts = filtered;
    },

    DELETE_ARCHIVED_CONTRACT(state, request_id) {
      let filtered = state.archived_contracts.filter((c) => c.id != request_id);
      state.archived_contracts = filtered;
    },
    ADD_CONTRACT(state, request) {
      state.contracts.unshift(request);
    },
    EDIT_CONTRACT(state, contract) {
      var index = state.contracts.findIndex((x) => x.id == contract.id);
      console.log("Response contract from Edit Action", contract);
      state.contracts.splice(index, 1, contract);
    },
    ADD_TO_ARCHIVED_CONTRACTS(state, request) {
      state.archived_contracts.unshift(request);
    },
    // BENEFICIARIES
    DELETE_BENEFICIARY(state, request) {
      let filtered = state.beneficiaries.filter((c_b) => c_b.id != request.id);
      state.contract.contract_beneficiaries = filtered;
    },
    ADD_BENEFICIARY(state, request) {
      console.log("current bene", state.beneficiaries);
      state.beneficiaries.push(request);
    },
    SET_BENEFICIARY(state, request) {
      state.beneficiaries = request;
    },
    ADD_CONTRACT_SUMMARY(state, request) {
      state.contract_summary = request;

      var add_contract_headers = ["Date", "Contracts", { role: "annotation" }];
      var add_sales_headers = ["Date", "Sales", { role: "annotation" }];

      state.contract_summary[0].summary.unshift(add_contract_headers);
      state.contract_summary[0].sales.unshift(add_sales_headers);
    },
    RESTORE_CONTRACT(state, request) {
      // loop through archived contracts then pop the request
      let filtered = state.archived_contracts.filter((a) => a.id != request.id);
      state.archived_contracts = filtered;
      request.contract_status = "Active";
      state.contracts.push(request);
    },
    // EDIT_BENEFICIARY(state, request) {
    //   // console.log(state.contract.beneficiaries);
    //   // console.log(state.contract.contract_beneficiaries);
    //   var counter = 0;
    //   state.contract.contract_beneficiaries.forEach((c_b) => {

    //     if (c_b.id == request.id) {
    //       console.log("found");
    //       state.contract.contract_beneficiaries.splice(counter, 0, request);
    //     } counter += 1;
    //   });
    // }
  },
  actions: {
    async delete_permanently({ commit }, contract_id) {
      await axios.delete("permanently_delete/" + contract_id);
      commit("DELETE_ARCHIVED_CONTRACT", contract_id);
    },

    async verify_contract({ commit }, request) {
      let response = await axios.put(
        "verify_contract/" + request.contract_id + "/" + request.verified_by
      );
      commit("EDIT_CONTRACT", response.data);
    },
    async get_beneficiaries({ commit }, request) {
      let response = await axios.get("/contract_beneficiary/" + request);
      commit("SET_BENEFICIARY", response.data);
      return response.data;
    },

    async member_signout({ commit }) {
      location.reload();
      commit("MEMBER_SIGNOUT");
    },

    async member_signin({ commit }, request) {
      let response = await axios.post("/member_login", request);
      if (response.status == 200) {
        // get contracts with payments and redirect to member dashboard
        let response1 = await axios.get("/member/" + response.data);
        if (response1.status == 200) {
          commit("SET_CONTRACT_WITH_PAYMENTS", response1.data);
        }
      } else {
        console.log("wrong credentials");
      }
    },
    // change contract status
    /* 
       1. edit contact in the database
       2. remove contract in the contracts array
       3. re-generate archived_contracts 
    */
    async restore({ commit }, request) {
      let response = await axios.post("restore", request);
      if (response.data == 1) {
        commit("RESTORE_CONTRACT", request);
      }
    },
    async redate({ commit }, request) {
      let response = await axios.post("redate", request);
      console.log(response.data);
      commit("EDIT_CONTRACT", response.data);
    },
    async change_contract_status({ commit }, request) {
      let response = await axios.post("/change_contract_status", request);
      // step 1
      // commit("EDIT_CONTRACT", response.data);
      // step 2
      commit("DELETE_CONTRACT", response.data.id);
      // step 3
      commit("ADD_TO_ARCHIVED_CONTRACTS", response.data);
    },
    // search contract
    async search_contract({ rootState, dispatch }, contract_no) {
      var data = null;
      rootState.contract.contracts.forEach((c) => {
        if (c.contract_no == contract_no) {
          data = c;
        }
      });
      // if data is null, search in paid contracts
      // check if paid contracts is null
      if (!rootState.contract.paid_contracts)
        await dispatch("get_paid_contracts");
      rootState.contract.paid_contracts.forEach((p) => {
        if (p.contract_no == contract_no) {
          data = p;
        }
      });
      return data;
    },
    // get archived_contracts
    async get_archived_contracts({ commit }) {
      let response = await axios.get("/archived_contracts");
      commit("SET_ARCHIVED_CONTRACTS", response.data);
    },
    // get contract summary
    async get_contract_summary({ commit }) {
      let response = await axios.get("/contracts_summary");
      commit("ADD_CONTRACT_SUMMARY", response.data);
    },
    // get contract
    async get_contract({ commit }, contract_id) {
      // console.log("get contract id",contract_id);
      let response = await axios.get("/contract/" + contract_id);
      console.log("return from server", response.data);
      commit("SET_CONTRACT", response.data);
    },
    // edit contract
    async edit_contract({ commit, dispatch, rootState }, request) {
      let response = await axios.put("/contract", request);
      commit("EDIT_CONTRACT", response.data);

      var action = {
        user_id: rootState.auth.user.user_id,
        name: rootState.auth.user.name,
        action: "verified contract",
        contract_no: request.contract_no,
        date_time: new Date(),
      };
      dispatch("action/add_action", action, { root: true });
    },
    // add contract
    async add_contract({ commit, dispatch, rootState }, request) {
      let response = await axios.post("/contract", request);
      if (response.data == "Contract already exist in post") {
        alert(response.data);
        return false;
      } else {
        commit("ADD_CONTRACT", response.data);
        var action = {
          user_id: rootState.auth.user.user_id,
          name: rootState.auth.user.name,
          action: "added contract",
          contract_no: request.contract_no,
          date_time: new Date(),
        };
        dispatch("action/add_action", action, { root: true });
        return true;
      }
    },
    // delete contract
    async delete_contract({ commit, dispatch, rootState }, contract_id) {
      let response = await axios.delete("/contract/" + contract_id);
      if (response.data == "0") {
        alert("ERROR: Please contact administrator");
      } else {
        commit("DELETE_CONTRACT", contract_id);
        var action = {
          user_id: rootState.auth.user.id,
          name: rootState.auth.user.first_name,
          action: "deleted",
          contract_id: contract_id,
          date_time: new Date(),
        };
        dispatch("action/add_action", action, { root: true });
      }

      // window.localStorage.setItem(`action_` + action.user_id, JSON.stringify(action));
    },
    // filter_contracts
    async filter_contracts({ commit }, request) {
      // console.log(request);
      let response = await axios.post("/contract/filter", request);
      commit("SET_CONTRACTS", response.data);
    },
    // get contracts
    async get_unpaid_contracts({ commit }) {
      let response = await axios.get("/get_unpaid_contracts");
      commit("SET_CONTRACTS", response.data);
    },
    async get_paid_contracts({ commit }) {
      let response = await axios.get("/get_paid_contracts");
      commit("SET_PAID_CONTRACTS", response.data);
    },

    // get contracts_consolidation
    async get_contracts_consolidation({ commit }) {
      let response = await axios.get("/contracts_consolidation");
      commit("SET_CONTRACTS_CONSOLIDATION", response.data);
    },

    // BENEFICIARIES
    async add_beneficiary({ rootState, dispatch, commit }, request) {
      console.log(request);
      //fixss
      let response = await axios.post("/contract_beneficiary", request);
      var action = {
        user_id: rootState.auth.user.id,
        name:
          rootState.auth.user.first_name + " " + rootState.auth.user.last_name,
        action: "add beneficiary",
        contract_id: request.contract_id,

        date_time: new Date(),
      };
      dispatch("action/add_action", action, { root: true });
      commit("ADD_BENEFICIARY", response.data);
    },

    async edit_beneficiary({ rootState, dispatch }, request) {
      await axios.put("/contract_beneficiary/" + request.id, request);
      var action = {
        user_id: rootState.auth.user.id,
        name:
          rootState.auth.user.first_name + " " + rootState.auth.user.last_name,
        action: "edited a beneficiary",
        contract_id: request.contract_id,
        id: request.id,
        date_time: new Date(),
      };
      dispatch("action/add_action", action, { root: true });
      // commit("EDIT_BENEFICIARY", response.data);
    },
    async delete_beneficiary({ rootState, dispatch, commit }, request) {
      console.log("delete");
      let response = await axios.delete("/contract_beneficiary/" + request.id);
      if (response.data == "0") {
        alert("ERROR: Please contact administrator");
      } else {
        commit("DELETE_BENEFICIARY", request);
        var action = {
          user_id: rootState.auth.user.id,
          name:
            rootState.auth.user.first_name +
            " " +
            rootState.auth.user.last_name,
          action: "deleted a beneficiary",
          contract_id: request.contract_id,
          id: request.id,
          date_time: new Date(),
        };
        dispatch("action/add_action", action, { root: true });
      }
    },
  },
};
