import axios from "axios";
export default {
  namespaced: true,
  state: {
    accounts: null,
  },
  getters: {
    accounts(state) {
      return state.accounts;
    },
  },
  mutations: {
    EDIT_ACCOUNT(state, account) {
      state.accounts.forEach((a) => {
        if (a.id == account.id) {
          a = account;
        }
      });
    },
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },
    ADD_ACCOUNT(state, account) {
      state.accounts.unshift(account);
    },
    DELETE_ACCOUNT(state, account_id) {
      let filtered = state.accounts.filter((a) => a.id != account_id);
      state.accounts = filtered;
    },
  },
  actions: {
    // edit account
    async edit_account({ commit }, request) {
      let response = await axios.put("/user/" + request.id, request);
      commit("EDIT_ACCOUNT", response.data);
    },
    // get accounts
    async get_accounts({ commit }) {
      let response = await axios.get("/users");
      commit("SET_ACCOUNTS", response.data);
    },
    // add account
    async add_account({ commit }, request) {
      let response = await axios.post("/user", request);
      commit("ADD_ACCOUNT", response.data);
    },
    // del account
    async delete_account({ commit }, request) {
      let response = await axios.delete("/user/" + request.id);
      if (response.data == 1) {
        commit("DELETE_ACCOUNT", request.id);
      } else {
        alert("Something is wrong");
      }
    },
  },
};
