<template>
  <v-dialog v-model="alert.showing" :width="alert.width">
    <v-card>
      <v-sheet class="pa-5 white--text" :color="alert.color">
        <h3>{{ alert.title }}</h3>
      </v-sheet>
      <v-card-text class="pa-10 text-center">
        <v-icon>mdi-alert</v-icon>
        <span> {{ alert.message }}</span>

        <br />
        <br />
        <span class="overline">
          {{ alert.footer }}
        </span>
        <v-btn
          :color="alert.color"
          small
          class="white--text mt-6 rounded-xl"
          @click="close_modal"
          >{{ alert.button_text }}</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      set_alert: "notification/set_alert",
      close_alert: "notification/close_alert",
    }),
    close_modal() {
      this.close_alert();
    },
  },
  computed: {
    ...mapGetters({
      alert: "notification/alert",
    }),
  },
};
</script>

<style></style>
