import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import Dashboard from "../views/Dashboard.vue";
import Contracts from "../views/Contracts.vue";
// import Payments from "../views/Payments.vue";
import Agents from "../views/Agents.vue";
import Archives from "../views/Archives.vue";
import Accounts from "../views/Accounts.vue";
import Profile from "../views/Profile.vue";
import Payment from "../views/Payment.vue";
import Signin from "../views/Signin.vue";
import Branches from "../views/Branches.vue";
// import MemberDashboard from "../views/MemberDashboard.vue";
import ContractProfile from "../components/Member/ContractView.vue";
import PaymentHistory from "../components/Member/PaymentView.vue";

Vue.use(VueRouter);

// PERMISSION LEGEND
/* 
4 - Admin
3 - Accounting
2 - Verifier
1 - Collector
0 - Encoder
*/
const routes = [
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (store.getters["auth/authenticated"]) {
        next({ name: "Admin Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/member/contract_profile/:contract_no/:contract_id",
    name: "Member Contract Profile",
    component: ContractProfile,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      next();
    },
  },
  {
    path: "/member/payment_history/:contract_no/:contract_id",
    name: "Member Payment History",
    component: PaymentHistory,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      next();
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/authenticated"]) {
        console.log(store.getters["auth/authenticated"]);

        return next({ name: "Signin" });
      } else {
        if (store.getters["auth/user"].permission != 4) {
          store.dispatch(
            "notification/set_notification",
            {
              message: "You are not allowed to enter this page!",
              color: "error",
              showing: true,
              icon: "mdi-alert",
            },
            { root: true }
          );

          return next({ name: "Contracts" });
        }
        next();
      }
    },
  },

  {
    path: "/contracts",
    name: "Contracts",
    component: Contracts,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/user"]) {
        next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/payment/:id",
    name: "Payments",
    component: Payment,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/user"]) {
        next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
  {
    path: "/branches",
    name: "Branches",
    component: Branches,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/user"]) {
        next({ name: "Signin" });
      } else {
        if (store.getters["auth/user"].permission != 4) {
          store.dispatch(
            "notification/set_notification",
            {
              message: "You are not allowed to enter this page!",
              color: "error",
              showing: true,
              icon: "mdi-alert",
            },
            { root: true }
          );

          return next({ name: "Contracts" });
        }
        next();
      }
    },
  },
  {
    path: "/agents",
    name: "Agents",
    component: Agents,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/user"]) {
        next({ name: "Signin" });
      } else {
        if (store.getters["auth/user"].permission != 4) {
          store.dispatch(
            "notification/set_notification",
            {
              message: "You are not allowed to enter this page!",
              color: "error",
              showing: true,
              icon: "mdi-alert",
            },
            { root: true }
          );

          return next({ name: "Contracts" });
        }
        next();
      }
    },
  },
  {
    path: "/accounts",
    name: "Accounts",
    component: Accounts,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/user"]) {
        next({ name: "Signin" });
      } else {
        if (store.getters["auth/user"].permission != 4) {
          store.dispatch(
            "notification/set_notification",
            {
              message: "You are not allowed to enter this page!",
              color: "error",
              showing: true,
              icon: "mdi-alert",
            },
            { root: true }
          );

          return next({ name: "Contracts" });
        }
        next();
      }
    },
  },
  {
    path: "/archives",
    name: "Archives",
    component: Archives,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/user"]) {
        next({ name: "Signin" });
      } else {
        if (store.getters["auth/user"].permission < 4) {
          store.dispatch(
            "notification/set_notification",
            {
              message: "You are not allowed to enter this page!",
              color: "error",
              showing: true,
              icon: "mdi-alert",
            },
            { root: true }
          );

          return next({ name: "Contracts" });
        }
        next();
      }
    },
  },
  {
    path: "/profile/:id",
    name: "Profile",
    component: Profile,
    beforeEnter: (to, from, next) => {
      document.title = "MOP-" + to.name;
      if (!store.getters["auth/user"]) {
        next({ name: "Signin" });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
