<template>
  <div>
    <v-dialog v-model="menu" width="600" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          class="rounded-xl"
          filled
          v-model="form.date"
          :label="label"
          :prepend-inner-icon="icon"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>

      <!-- CONTENT -->
      <v-card>
        <v-sheet color="blue" class="white--text">
          <p class="pa-4">
            <v-icon color="white" class="pr-2">mdi-calendar</v-icon>{{ label }}
          </p>
        </v-sheet>

        <v-card-text>
          <p>Please select a date</p>
          <v-row>
            <v-col>
              <v-select
                :items="years"
                label="Year"
                v-model="form.year"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="form.month"
                :items="items"
                item-text="name"
                item-value="value"
                label="Month"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select :items="days" label="Day" v-model="form.day"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex pa-3">
          <v-btn
            color="primary"
            class="rounded-xl d-flex-item justify-left mr-2"
            @click="add"
            >Add</v-btn
          >
          <v-btn class="rounded-xl" @click="menu = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    icon: String,
    label: String,
    placeholder: String,
  },
  data() {
    return {
      menu: false,
      items: [
        { name: "January", value: "1" },
        { name: "February", value: "2" },
        { name: "March", value: "3" },
        { name: "April", value: "4" },
        { name: "May", value: "5" },
        { name: "June", value: "6" },
        { name: "July", value: "7" },
        { name: "August", value: "8" },
        { name: "September", value: "9" },
        { name: "October", value: "10" },
        { name: "November", value: "11" },
        { name: "December", value: "12" },
      ],
      form: {
        date: "",
        year: "",
        day: "",
        month: "",
      },

      years: [],
      days: [],
    };
  },
  methods: {
    add() {
      this.form.date =
        this.form.year + "-" + this.form.month + "-" + this.form.day;
      this.$emit("set_date", this.form);
      this.menu = false;
    },
  },
  created() {
    var i;
    for (i = 2025; i >= 1910; i--) {
      this.years.push(i);
    }
    var x;
    for (x = 1; x <= 31; x++) {
      this.days.push(x);
    }
  },
};
</script>

<style></style>
