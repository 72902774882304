<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-sheet class="pa-5 white--text" color="blue">
      <h2>Add Payment</h2>
    </v-sheet>
    <v-card>
      <v-card-text class="pt-3">
        <v-form ref="form">
          <v-text-field
            :rules="rules"
            filled
            class="rounded-xl"
            label="PR Number"
            ref="pr_number"
            prepend-icon="mdi-file"
            v-model="form.pr_number"
          ></v-text-field>
          <v-text-field
            :rules="rules"
            prepend-icon="mdi-cash"
            filled
            class="rounded-xl"
            label="Amount"
            ref="amount"
            v-model="form.amount_paid"
          ></v-text-field>
          <div class="d-flex ml-2">
            <p>Payment Date:</p>
            <input class="mt-n4 ml-4" type="date" v-model="form.payment_date" />
          </div>
          <p class="ml-2">Posted by: {{ form.posted_by }}</p>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="add" color="blue" class="white--text rounded-xl"
          >Add</v-btn
        >
        <v-btn @click="cancel" class="rounded-xl">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <progress-linear :dialog="progress_dialog" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ProgressLinear from "./ProgressLinear.vue";
import moment from "moment";
export default {
  components: { ProgressLinear },
  props: ["contract"],
  data() {
    return {
      progress_dialog: false,
      rules: [(value) => !!value || "required"],
      dialog: true,
      form: {
        contract_id: "",
        payment_date: "",
        pr_number: "",
        amount_paid: "",
        posted_by: "",
      },
    };
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      get_payment: "payment/get_payment",
      add_payment: "payment/add_payment",
    }),
    cancel() {
      this.$emit("close_add_payment_modal");
    },
    async add() {
      switch (this.user.permission) {
        case "4":
          break;
        case "3":
          this.set_notification({
            message: "You are not allowed to do this action!",
            color: "error",
            showing: true,
            icon: "mdi-alert",
          });
          return;
        case "2":
          break;
        case "1":
          break;
        case "0":
          this.set_notification({
            message: "You are not allowed to do this action!",
            color: "error",
            showing: true,
            icon: "mdi-alert",
          });
          return;
      }

      if (this.$refs.form.validate()) {
        this.progress_dialog = true;
        let add_array = await this.add_payment(this.form);
        if (add_array[1] == 0) {
          this.progress_dialog = false;
          return this.set_notification({
            message: add_array[0],
            color: "error",
            showing: true,
            icon: "mdi-alert",
          });
        }

        this.progress_dialog = false;
        this.set_notification({
          message: "Payment Inserted",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        // display sms api message
        alert(add_array[0]);
      } else {
        this.set_notification({
          message: "Some fields are missing. Please try again",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      payment: "payment/payment",
    }),
    current_user() {
      var user = this.user.last_name + ", " + this.user.first_name;
      return user;
    },
  },
  created() {
    //   SET INITIAL FORM VALUES
    this.form.payment_date = moment().format("Y-MM-DD");
    console.log(this.payment_date);
    this.form.contract_id = this.contract.id;
    this.form.amount_paid = this.contract.installment_value;
    this.form.posted_by = this.current_user;
  },
};
</script>

<style></style>
