<template>
  <v-container>
    <v-card class="pa-5 ml-2 mr-2 rounded-xl" v-if="ready"
      ><v-row>
        <v-col cols="2">
          <v-btn
            small
            color="red"
            class="rounded-xl white--text"
            @click="add_beneficiary_dialog = true"
            >Add Beneficiary
          </v-btn>
          <add-beneficiary-modal
            :beneficiaries="beneficiaries"
            :dialog="add_beneficiary_dialog"
            @close_beneficiary_dialog="add_beneficiary_dialog = false"
          />
        </v-col>
        <v-col cols="2">
          <v-btn class="rounded-xl" color="yellow " small @click="verify"
            >Verify Account</v-btn
          >
          <!-- <v-btn class="ml-2" color="green">Confirm Edit Account</v-btn> -->
        </v-col>
        <v-col cols="2">
          <v-btn
            small
            class="rounded-xl white--text"
            color="blue "
            @click="print_contract"
            >Print Contract</v-btn
          >
          <!-- <v-btn class="ml-2" color="green">Confirm Edit Account</v-btn> -->
        </v-col>
        <v-col cols="2">
          <v-btn small class="rounded-xl" color="" @click="update_contract"
            >Update Contract</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-first">
          <!-- Verified By -->
          <p>
            Verified by:
            <span class="green--text font-weight-bold">
              {{ contract.verified_by }}
            </span>
          </p>
          <p class="ml-5 mr-5">
            Verified Date:
            <span class="green--text font-weight-bold">{{
              contract.verified_date
            }}</span>
          </p>
          <v-divider vertical></v-divider>
          <p class="ml-5">
            Updated by:
            <span v-if="contract.updated_by == ''" class="font-weight-bold"
              >None</span
            >
            <span v-else class="green--text font-weight-bold">{{
              contract.updated_by
            }}</span>
          </p>
          <p class="ml-5">
            Last Update:
            <span class="green--text font-weight-bold">{{
              format_date(contract.updated_at)
            }}</span>
          </p>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-form ref="form">
            <v-card-text>
              <div class="">
                <h1 class="display-1 font-weight-thin">
                  <v-icon x-large class="mr-2">mdi-file-outline</v-icon>
                  Contract Information
                </h1>
                <!-- FIRST ROW -->
                <v-row class="pt-8">
                  <!-- FIRST COL -->
                  <v-col>
                    <!-- CONTRACT NUMBER -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :rules="rules.required"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-file"
                      v-model="contract.contract_no"
                      filled
                      color="blue-grey lighten-2"
                      label="Contract Number"
                    />
                    <!-- DATE CREATED -->

                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      filled
                      v-model="contract.date_created"
                      label="Application Date"
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>

                    <!-- END DATE CREATED -->

                    <!-- PLAN TYPE -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.plan_type"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-flower-outline"
                      :items="plan_type"
                      filled
                      label="Plan Type"
                    ></v-select>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col>
                    <!-- CONTRACT PRICE -->

                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.contract_price"
                      filled
                      color="blue-grey lighten-2"
                      label="Contract Price"
                      prepend-inner-icon="mdi-currency-usd"
                    ></v-text-field>
                    <!-- BASE VALUE -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.base_value"
                      filled
                      color="blue-grey lighten-2"
                      label="Base Value"
                      prepend-inner-icon="mdi-scale-balance"
                    ></v-text-field>
                    <!-- INSURABLE -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.insurable"
                      :class="text_field_padding"
                      prepend-inner-icon=""
                      :items="this.insurable"
                      item-text="text"
                      item-value="val"
                      filled
                      label="Insurable"
                    ></v-select>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col>
                    <!-- MODE OF PAYMENT -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.mode_of_payment"
                      :class="text_field_padding"
                      prepend-inner-icon=""
                      :items="mode_of_payment"
                      filled
                      label="Mode of Payment"
                    ></v-select>
                    <!-- MODE OF INSTALLMENT -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.mode_of_installments"
                      :class="text_field_padding"
                      prepend-icon=""
                      :items="mode_of_installments"
                      item-text="text"
                      item-value="val"
                      filled
                      label="Mode of Installment"
                    ></v-select>
                    <!-- INITIAL PAYMENT -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.initial_payment"
                      filled
                      color="blue-grey lighten-2"
                      label="Initial Payment"
                      prepend-inner-icon="mdi-currency-usd"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 4th COL -->
                  <v-col>
                    <!-- INSTALLMENT VALUE -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="text_field rounded-xl"
                      width="150"
                      :class="text_field_padding"
                      v-model="contract.installment_value"
                      filled
                      color="blue-grey lighten-2"
                      label="Installment Value"
                      prepend-inner-icon="mdi-currency-usd"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
              <!-- <br />
          <v-divider max-width="600" ></v-divider> -->
              <div class="pt-8">
                <h1 class="display-1 font-weight-thin">
                  <v-icon x-large class="mr-2">mdi-account-outline</v-icon>
                  Personal Information
                </h1>
                <!-- SECOND ROW -->
                <v-row class="pt-8">
                  <!-- 1st COL -->
                  <v-col cols="4">
                    <!-- CUSTOMER NAME -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_name"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Name"
                      prepend-inner-icon="mdi-account"
                    ></v-text-field>
                    <!-- CUSTOMER ADDRESS -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_address"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Address"
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                    <!-- CUSTOMER BUSINESS ADDRESS -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_business_address"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Business Address"
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                    <!-- CUSTOMER BIRTHDATE -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      filled
                      v-model="contract.customer_birthdate"
                      label="Customer Birthdate"
                      prepend-inner-icon="mdi-cake"
                    >
                    </v-text-field>

                    <!-- END CUSTOMER BIRTHDATE -->
                    <!-- CIVIL STATUS -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.customer_civil_status"
                      :class="text_field_padding"
                      prepend-icon=""
                      :items="customer_civil_status"
                      filled
                      label="Civil Status"
                    ></v-select>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col cols="4">
                    <!-- GENDER -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.customer_gender"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-gender-male-female"
                      :items="customer_gender"
                      item-text="text"
                      item-value="val"
                      filled
                      label="Gender"
                    ></v-select>
                    <!-- RELIGION -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_religion"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Religion:"
                      prepend-inner-icon="mdi-church"
                    >
                    </v-text-field>

                    <!-- CUSTOMER ANNUAL INCOME -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_annual_income"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Annual Income"
                      prepend-inner-icon="mdi-currency-usd"
                    ></v-text-field>
                    <!-- CUSTOMER TIN -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_tin"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer TIN"
                      prepend-inner-icon="mdi-barcode"
                    ></v-text-field>
                    <!-- CUSTOMER SSS -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_sss"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer SSS"
                      prepend-inner-icon="mdi-barcode"
                    ></v-text-field>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col cols="4">
                    <!-- CUSTOMER TEL -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_tel"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Telephone Number"
                      prepend-inner-icon="mdi-phone"
                    >
                    </v-text-field>
                    <!-- CUSTOMER MOBILE -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_mobile"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Mobile Number"
                      prepend-inner-icon="mdi-cellphone-basic"
                    ></v-text-field>
                    <!-- CUSTOMER EMAIL -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_email"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Email"
                      prepend-inner-icon="mdi-email"
                    >
                    </v-text-field>
                    <!-- CUSTOMER OCCUPATION -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.customer_occupation"
                      filled
                      color="blue-grey lighten-2"
                      label="Customer Occupation"
                      prepend-inner-icon="mdi-briefcase-outline"
                    ></v-text-field>
                  </v-col>
                  <!-- 4th COL  -->
                  <!-- <v-col cols="3"> </v-col> -->
                </v-row>
              </div>

              <div class="pt-8">
                <h1 class="display-1 font-weight-thin">
                  <v-icon x-large class="mr-2">mdi-account</v-icon>
                  Payor's Information
                </h1>
                <!-- THIRD ROW -->
                <v-row class="pt-8">
                  <!-- 1st COL -->
                  <v-col>
                    <!-- SAME PAYOR? -->
                    <v-select
                      :readonly="!edit_fields"
                      @change="change_payor_relation_state"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.same_payor"
                      :items="same_payor"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-help-circle-outline"
                      item-text="text"
                      item-value="val"
                      filled
                      label="Same as Principal Member"
                    ></v-select>
                    <!-- PAYOR RELATION -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_relation"
                      filled
                      color="blue-grey lighten-2"
                      label="Payor Relation"
                      prepend-inner-icon=""
                    ></v-text-field>
                    <!-- PAYOR NAME -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_name"
                      filled
                      color="blue-grey lighten-2"
                      label="Payor Name"
                      prepend-inner-icon="mdi-account-outline"
                    ></v-text-field>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col>
                    <!-- PAYOR ADDRESS -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_address"
                      filled
                      color="blue-grey lighten-2"
                      label="Payor Address"
                      prepend-inner-icon="mdi-map-marker"
                    ></v-text-field>
                    <!-- PAYOR BIRTHDATE -->

                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      filled
                      v-model="contract.payor_birthdate"
                      label="Payor Birthdate"
                      prepend-inner-icon="mdi-cake"
                    >
                    </v-text-field>

                    <!-- PAYOR CIVIL STATUS -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.payor_civil_status"
                      :class="text_field_padding"
                      prepend-icon=""
                      :items="payor_civil_status"
                      filled
                      label="Payor Civil Status"
                    ></v-select>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col>
                    <!-- PAYOR GENDER -->
                    <v-select
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      v-model="contract.payor_gender"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-gender-male-female"
                      :items="payor_gender"
                      item-text="text"
                      item-value="val"
                      filled
                      label="Payor Gender"
                    ></v-select>
                    <!-- PAYOR TEL -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_tel"
                      filled
                      color="blue-grey lighten-2"
                      label="Payor Telephone Number"
                      prepend-inner-icon="mdi-phone"
                    >
                    </v-text-field>
                    <!-- PAYOR MOBILE -->
                    <v-text-field
                      :readonly="!edit_fields"
                      :rules="rules.required"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_mobile"
                      filled
                      color="blue-grey lighten-2"
                      label="Payor Mobile Number"
                      prepend-inner-icon="mdi-cellphone-basic"
                    ></v-text-field>
                  </v-col>
                  <!-- 4th COL -->
                  <v-col>
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.payor_email"
                      filled
                      color="blue-grey lighten-2"
                      label="Payor Email"
                      prepend-inner-icon="mdi-email"
                    ></v-text-field>
                  </v-col>
                  <!-- PAYOR EMAIL -->
                </v-row>
              </div>
              <div class="pt-8">
                <h1 class="display-1 font-weight-thin ml-4">
                  <v-icon x-large class="mr-2">mdi-account-group</v-icon>
                  Beneficiaries
                </h1>
                <!-- FOURTH ROW -->
                <v-row class="pt-5">
                  <v-col>
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Beneficiary ID</th>
                          <th>Contract ID</th>
                          <th>Name</th>
                          <th>Birth Date</th>
                          <th>Relationship</th>
                          <th>Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="bene in beneficiaries" :key="bene.id">
                          <td>{{ bene.id }}</td>
                          <td>{{ bene.contract_id }}</td>
                          <td>{{ bene.name }}</td>
                          <td>{{ bene.birth_date }}</td>
                          <td>{{ bene.relationship }}</td>
                          <td>{{ bene.address }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <div class="pt-8">
                <!-- FIFTH ROW -->
                <v-row class="pt-8">
                  <!-- 1st COL -->
                  <v-col>
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      filled
                      v-model="contract.signed_date"
                      label="Signed Date"
                      prepend-inner-icon="mdi-calendar"
                    ></v-text-field>

                    <!-- END SIGNED DATE -->
                    <!-- ENCODER -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.encoder"
                      filled
                      color="blue-grey lighten-2"
                      label="Encoded by:"
                      prepend-inner-icon="mdi-typewriter"
                    >
                    </v-text-field>
                    <!-- ENCODED DATE -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.encoded_date"
                      filled
                      color="blue-grey lighten-2"
                      label="Encoded Date:"
                      prepend-inner-icon="mdi-calendar"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- 2nd COL -->
                  <v-col>
                    <!-- SIGNED PLACE -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.signed_address"
                      filled
                      color="blue-grey lighten-2"
                      label="Signed Place:"
                      prepend-inner-icon=""
                    ></v-text-field>

                    <!-- COMMISSION Percentage -->
                    <v-text-field
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      :class="text_field_padding"
                      v-model="contract.agent_commission_percentage"
                      filled
                      color="blue-grey lighten-2"
                      label="Commission Percentage:"
                      prepend-inner-icon=""
                    ></v-text-field>
                    <!-- BUSINESS MANAGER -->
                    <v-select
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      v-model="contract.business_manager"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-account-tie"
                      :items="business_manager"
                      filled
                      label="Business Manager"
                    >
                    </v-select>
                  </v-col>
                  <!-- 3rd COL -->
                  <v-col>
                    <!-- AGENTS -->
                    <v-select
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      v-model="contract.agent_id"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-account-group"
                      :items="formatted_agents"
                      item-text="full_name"
                      item-value="id"
                      filled
                      label="Agent"
                    ></v-select>
                    <!-- BRANCHES -->
                    <v-select
                      :readonly="!edit_fields"
                      class="rounded-xl"
                      v-model="contract.branch_id"
                      :class="text_field_padding"
                      prepend-inner-icon="mdi-domain"
                      :items="branches"
                      item-text="branch_name"
                      item-value="id"
                      filled
                      label="Branch"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-divider max-width="600"></v-divider>
            <v-card-actions class="text-center">
              <p class="lighten-2 caption mt-2">
                <v-icon color="white" class="pr-2">mdi-information</v-icon>
                Note: Verify button is at the right top of this modal. Please
                review your fields carefully
              </p>
            </v-card-actions>
            <div v-if="show_alert">
              <!-- CONFIRM NOTIFICATION -->
              <confirm-notification
                icon="mdi-alert-outline"
                class="alert"
                type="warning"
                :message="this.alert_message"
                @confirm-event="proceed"
                @cancel-event="close_confirm"
              />
            </div>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
    <loading-alert-modal
      :id="alert_id"
      :data="alert_data"
      :dialog="alert_modal_dialog"
    />
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>

<script>
import ConfirmNotification from "@/components/ConfirmNotification.vue";
import { mapGetters, mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
import AddBeneficiaryModal from "@/components/AddBeneficiaryModal.vue";
import LoadingAlertModal from "../components/LoadingAlertModal.vue";
import moment from "moment";
export default {
  components: {
    ConfirmNotification,
    ProgressLinear,
    AddBeneficiaryModal,
    LoadingAlertModal,
  },
  data() {
    return {
      edit_fields: false,
      alert_id: "",
      alert_data: "",
      alert_modal_dialog: false,
      add_beneficiary_dialog: false,
      default_insurable: "1",
      text_field_padding: "pr-2 pl-2",
      dialog: false,
      name: "",
      color: "grey",
      input_text_color: "",
      title: "",
      date_created_modal1: false,
      date: "",
      ready: false,
      progress_dialog: false,
      show_alert: false,
      alert_message: "",
      payor_disabled: false,
      rules: {
        required: [(value) => !!value || "Field is required"],
      },
      beneficiary_headers: [
        { text: "id", value: "id" },
        { text: "Contract ID", value: "contract_id" },
      ],
      same_payor: [
        { val: "1", text: "Yes" },
        { val: "0", text: "No" },
      ],
      insurable: [
        { val: "1", text: "Yes" },
        { val: "0", text: "No" },
      ],
      formatted_agents: "",
      plan_type: [
        "GENESIS",
        "NUMBERS",
        "PROVERBS",
        "EXODUS",
        "DEUTERONOMY",
        "ECCLESIASTES",
        "LEVITICUS",
        "PSALMS",
      ],
      mode_of_payment: ["SPOT CASH", "5 YEARS INSTALLMENT"],
      business_manager: ["JULIUS CAESAR MATA", "LOLITA MATA", "HENRY MATA"],
      mode_of_installments: ["MONTHLY", "QUARTERLY", "SEMI-ANNUAL", "ANNUAL"],
      customer_civil_status: ["SINGLE", "MARRIED", "WIDOW/ER", "SEPARATED"],
      payor_civil_status: ["SINGLE", "MARRIED", "WIDOW/ER", "SEPARATED"],
      customer_gender: ["MALE", "FEMALE"],
      payor_gender: ["MALE", "FEMALE"],
    };
  },

  computed: {
    ...mapGetters({
      agents: "agent/agents",
      user: "auth/user",
      branches: "branch/branches",
      beneficiaries: "contract/beneficiaries",
      contracts: "contract/contracts",
      contract: "contract/contract",
      default_server_url: "auth/default_server_url",
      token: "auth/token",
    }),
  },
  methods: {
    format_date(date) {
      var new_date = moment(date).format("LLLL");
      return new_date;
    },
    ...mapActions({
      set_notification: "notification/set_notification",
      edit_contract: "contract/edit_contract",
      get_contract: "contract/get_contract",
      get_contracts: "contract/get_contracts",
      get_agents: "agent/get_agents",
      get_branches: "branch/get_branches",
      get_beneficiaries: "contract/get_beneficiaries",
      verify_contract: "contract/verify_contract",
    }),
    change_payor_relation_state() {
      this.payor_disabled = !this.payor_disabled;
    },
    close_confirm() {
      this.show_alert = false;
    },
    async proceed() {
      this.show_alert = false;
      this.progress_dialog = true;
      // set this.contract.verified = current user's name
      var verified_by = this.user.first_name + " " + this.user.last_name;
      var contract_id = this.contract.id;
      var request_obj = {
        verified_by: verified_by,
        contract_id: contract_id,
      };
      await this.verify_contract(request_obj).catch((e) => {
        alert(e.message);
        this.progress_dialog = false;
      });

      this.set_notification({
        message: "You have successfully verified a contract",
        color: "success",
        showing: true,
        icon: "mdi-check",
      });

      this.progress_dialog = false;
    },
    async get_formatted_agents() {
      var arr = [];
      this.agents.map((x) => {
        var new_a = {
          id: x.id,
          full_name: x.surname + ", " + x.name,
        };
        arr.push(new_a);
      });
      return arr;
    },

    open() {
      this.dialog = true;
    },
    async update_contract() {
      this.progress_dialog = true;
      const updated_by = `${this.user.first_name} ${this.user.last_name}`;
      if (
        this.user.permission === "2" ||
        this.user.permission === "1" ||
        this.user.permission === "0"
      ) {
        alert("You are not allowed to do this action");
        this.progress_dialog = false;
        return;
      }
      if (this.$refs.form.validate()) {
        this.contract.updated_by = updated_by;
        await this.edit_contract(this.contract).catch((err) => {
          alert(err);
        });
        this.set_notification({
          message: "Successfully Updated",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      } else {
        this.set_notification({
          message: "Error: Please check your fields",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      }
      this.progress_dialog = false;
    },
    verify() {
      if (
        this.user.permission === "2" ||
        this.user.permission === "1" ||
        this.user.permission === "0"
      ) {
        alert("You are not allowed to do this action");
        return;
      }
      if (this.$refs.form.validate()) {
        this.show_alert = true;
        this.alert_message =
          "Your name will be inserted in the Verified column within contract. Are you sure you want to verify this account?";
      } else {
        this.set_notification({
          message: "Error: Please check your fields",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
    print_contract() {
      var url =
        this.default_server_url +
        "contract/" +
        this.token +
        "/" +
        this.$route.params.id;
      window.open(url);
    },
    async set_contract() {
      // open alert
      this.alert_id = "set contract";
      this.alert_data = "Contract Data";
      this.alert_modal_dialog = true;
      // check if verifier or cashier
      await this.get_contract(this.$route.params.id);
      this.progress_dialog = false;
      // insert data into arrays

      this.mode_of_payment.splice(0, 0, this.contract.mode_of_payment);
      this.mode_of_installments.splice(
        0,
        0,
        this.contract.mode_of_installments
      );
      this.customer_gender.splice(0, 0, this.contract.customer_gender);
      this.customer_civil_status.splice(
        0,
        0,
        this.contract.customer_civil_status
      );
      this.payor_civil_status.splice(0, 0, this.contract.payor_civil_status);
      this.payor_gender.splice(0, 0, this.contract.payor_gender);
      this.contract.insurable = String(this.contract.insurable);
      this.contract.same_payor = String(this.contract.same_payor);

      var x = await this.get_formatted_agents();
      this.formatted_agents = x;
      this.alert_modal_dialog = true;
      return true;
    },
    async get_necesarries() {
      var is_agent = this.user.role == "Administrator" ? 1 : 0;
      if (!this.contracts) await this.get_contracts();
      if (!this.agents) await this.get_agents(is_agent);
      if (!this.branches) await this.get_branches();
      return true;
    },
    async get_bene() {
      this.alert_modal_dialog = true;
      this.show_loading_modal("bene", "Beneficiaries");
      await this.get_beneficiaries(this.$route.params.id);
      this.alert_modal_dialog = false;
    },
    async show_loading_modal(alert_id, alert_data) {
      this.alert_id = alert_id;
      this.alert_data = alert_data;
    },
    async check_access() {
      if (
        this.user.role == "Administrator" ||
        this.user.role == "Contract Validator"
      )
        this.edit_fields = true;
    },
  },

  async created() {
    await this.get_necesarries();
    await this.set_contract();
    await this.get_bene();
    await this.check_access();
    this.ready = true;
  },
};
</script>

<style></style>
