<template>
  <v-container>
    <div class="pl-10" v-if="agents">
      <div>
        <v-card class="">
          <v-card-text>
            <v-row>
              <v-col>
                <add-agent-modal />
              </v-col>
              <v-col class="ml-5">
                <v-text-field
                  placeholder="Search anything..."
                  prepend-inner-icon="mdi-magnify"
                  max-width="300"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table :search="search" :items="agents" :headers="headers">
              <!-- clear agent -->
              <template v-slot:[`item.clear`]="{ item }" v-if="hide_clear">
                <div class="text-center">
                  <v-icon class="mr-2" color="primary " @click="clear(item)">
                    mdi-eraser
                  </v-icon>
                  <span class="caption primary--text">
                    <strong>Clear</strong>
                  </span>
                </div>
              </template>
              <!-- edit agent -->
              <template v-slot:[`item.edit`]="{ item }">
                <div class="text-center">
                  <v-icon class="mr-2" color="yellow " @click="edit(item)">
                    mdi-pencil
                  </v-icon>
                  <span class="caption yellow--text">
                    <strong>Edit</strong>
                  </span>
                </div>
              </template>
              <!-- delete agent -->
              <template v-slot:[`item.delete`]="{ item }">
                <div class="text-center" v-if="item.contracts_count == '0'">
                  <v-icon class="mr-2" color="red " @click="del(item)">
                    mdi-delete
                  </v-icon>
                  <span class="caption red--text">
                    <strong>Delete</strong>
                  </span>
                </div>
                <div class="text-center" v-else>
                  <v-icon class="mr-2" color="grey "> mdi-delete </v-icon>
                  <span class="caption grey--text">
                    <strong>Delete</strong>
                  </span>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <edit-agent-modal
      v-if="edit_agent_dialog"
      :item="edit_item"
      @close_edit_agent_dialog="close_edit_agent_dialog"
    />
    <progress-linear :dialog="progress_dialog" />
  </v-container>
</template>

<script>
import ProgressLinear from "@/components/ProgressLinear.vue";
import AddAgentModal from "@/components/AddAgentModal.vue";
import { mapGetters, mapActions } from "vuex";
import "chartkick/chart.js";
import EditAgentModal from "../components/EditAgentModal.vue";
export default {
  name: "Dashboard",

  components: {
    AddAgentModal,
    ProgressLinear,
    EditAgentModal,
  },
  computed: {
    ...mapGetters({
      agents: "agent/agents",
      user: "auth/user",
    }),
    contracts_count() {
      return "12";
    },
  },
  data() {
    return {
      hide_clear: false,
      edit_agent_dialog: false,
      edit_item: null,
      progress_dialog: false,
      search: "",
      rules: {
        number_rule: [(v) => v.length >= 3 || "Minimum length is 3 characters"],
        age_rule: [(v) => v.length >= 3 || "Minimum length is 1 character"],
        file_rule: [(v) => !!v || "File is required"],
      },
      headers: [
        { text: "", value: "clear" },
        { text: "", value: "edit" },
        { text: "", value: "delete" },

        { text: "Agent ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Surname", value: "surname" },
        { text: "Date Cleared", value: "date_cleared" },
        { text: "Commission", value: "commission" },
        { text: "Contracts Count", value: "contracts_count" },
        { text: "Branch", value: "branch.branch_name" },
        { text: "Phone Number", value: "phone_number" },
      ],
      chartData: [
        ["Year", "Sales", "Expenses", "Profit"],
        ["2014", 1000, 400, 200],
        ["2015", 1170, 460, 250],
        ["2016", 660, 1120, 300],
        ["2017", 1030, 540, 350],
      ],
      chartOptions: {
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017",
        },
      },
    };
  },
  created() {
    if (this.user.permission != 4) {
      this.headers.splice(0, 1);
      this.hide_clear = true;
    }
    if (!this.agents) {
      this.progress_dialog = true;
      this.get_agents(1).then(() => {
        this.get_branches().then(() => {
          this.progress_dialog = false;
        });
      });
    }
  },

  methods: {
    ...mapActions({
      get_agents: "agent/get_agents",
      clear_agent: "agent/clear_agent",
      set_notification: "notification/set_notification",
      delete_agent: "agent/delete_agent",
      get_branches: "branch/get_branches",
    }),
    close_edit_agent_dialog() {
      this.edit_agent_dialog = false;
    },
    clear(item) {
      this.progress_dialog = true;
      this.clear_agent(item).then(() => {
        this.progress_dialog = false;
        this.set_notification({
          message: "You have successfully cleared an agent",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      });
    },
    edit(item) {
      this.edit_agent_dialog = true;
      this.edit_item = item;
    },
    del(item) {
      this.progress_dialog = true;
      this.delete_agent(item).then(() => {
        this.progress_dialog = false;
        this.set_notification({
          message: "You have successfully deleted an agent",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      });
    },
  },
};
</script>
