<template>
  <div>
    <v-btn small color="teal" class="rounded-xl ml-2 white--text" @click="open">
      <v-icon class="mr-1">mdi-clipboard-list</v-icon> View Paid Contracts
    </v-btn>
    <v-dialog persistent v-model="dialog" width="1200">
      <v-card>
        <v-card-title
          >Paid Contracts <v-spacer></v-spacer>
          <v-btn
            class="rounded-xl white--text"
            small
            color="red"
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="progress_dialog == false">
          <v-card class="" flat>
            <v-row class="pr-5 pl-5">
              <v-col cols="5" class="d-flex justify-first">
                <v-select
                  v-model="selected_header"
                  :items="sortable_headers"
                  item-text="header_name"
                  item-value="value"
                  label="Search for"
                  @change="search_for"
                ></v-select>

                <v-text-field
                  v-model="search"
                  prepend-icon="mdi-magnify"
                  max-width=""
                  placeholder="Enter a value..."
                >
                </v-text-field>
                <v-btn
                  class="rounded-xl white--text"
                  small
                  color="blue"
                  depressed
                  outlined
                  @click="print_data"
                  >Print Data
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              v-if="paid_contracts"
              dense
              sort-by="created_at"
              :headers="contract_headers"
              :items="paid_contracts"
              multi-sort
              :search="search"
              :options="options"
            >
              <!-- view profile -->
              <template v-slot:[`item.profile`]="{ item }">
                <div class="text-center">
                  <v-icon class="mr-2" @click="profile(item)" color="primary">
                    mdi-account-circle
                  </v-icon>
                  <span class="caption primary--text">
                    <strong>Contract</strong>
                  </span>
                </div>
              </template>
              <!-- view payments -->
              <template v-slot:[`item.payment`]="{ item }">
                <div class="text-center">
                  <v-icon
                    class="mr-2"
                    color="yellow en-2"
                    @click="payment(item)"
                  >
                    mdi-cash-multiple
                  </v-icon>
                  <span class="caption yellow--text text--en-2">
                    <strong>Payments</strong>
                  </span>
                </div>
              </template>
              <!-- delete contract -->
              <!-- <template v-slot:[`item.delete`]="{ item }">
              <div class="text-center">
                <v-icon class="mr-2" color="red" @click="delete_contract(item)">
                  mdi-delete
                </v-icon>
                <span class="caption red--text">
                  <strong>Delete</strong>
                </span>
              </div>
            </template> -->
              <!-- Insurable -->
              <template v-slot:[`item.insurable`]="{ item }">
                <div class="text-center">
                  <span class="success--text" v-if="item.insurable == 1">
                    Yes
                  </span>
                  <span class="primary--text" v-else> No </span>
                </div>
              </template>
              <!-- Account Status -->
              <!-- <template v-slot:[`item.contract_status`]="{ item }">
              <div class="text-center">
                <span class="success--text" v-if="item.contract_status == 'Active'">
                  Active
                </span>
                <span class="warning--text" v-else>Pending </span>
              </div>
            </template> -->
              <!-- Agent -->
              <template v-slot:[`item.agent`]="{ item }">
                <div class="text-center">
                  <span> {{ item.agent.surname }}, {{ item.agent.name }} </span>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <progress-linear :dialog="progress_dialog" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProgressLinear from "./ProgressLinear.vue";
export default {
  components: {
    ProgressLinear,
  },
  data() {
    return {
      dialog: false,
      progress_dialog: false,
      selected_header: null,
      search: "",
      sortable_headers: [
        { header_name: "Id", value: "id" },
        { header_name: "Contract Number", value: "contract_no" },
        { header_name: "Customer Name", value: "customer_name" },
        { header_name: "Plan Type", value: "plan_type" },
        { header_name: "Agent", value: "agent" },
        { header_name: "Insurable", value: "insurable" },
        {
          header_name: "Installment Value",
          value: "installment_value",
        },
        { header_name: "Date Created", value: "date_created" },
        // { header_name: "Contract Status", value: "contract_status" },
      ],
      contract_headers: [
        { text: "", value: "profile" },
        { text: "", value: "payment" },
        // { text: "", value: "delete" },
        { text: "ID", value: "id", filterable: false },
        {
          text: "Contract Number",
          value: "contract_no",
          filterable: false,
        },
        {
          text: "Customer Name",
          value: "customer_name",
          filterable: false,
        },
        {
          text: "Plan Type",
          value: "plan_type",
          filterable: false,
        },
        {
          text: "Agent",
          value: "agent",
          filterable: false,
        },
        {
          text: "Insurable",
          value: "insurable",
          filterable: false,
          width: "100",
        },
        {
          text: "Installment Value",
          value: "installment_value",
          filterable: false,
        },
        {
          text: "Date Created",
          value: "date_created",
          filterable: false,
        },
        // {
        //   text: "Contract Status",
        //   value: "contract_status",
        //   filterable: false,
        // },
      ],
      options: {
        itemsPerPage: 15,
      },
    };
  },
  methods: {
    ...mapActions({
      get_paid_contracts: "contract/get_paid_contracts",
    }),
    async open() {
      await this.get_necessaries();
      this.dialog = true;
    },
    async get_necessaries() {
      this.progress_dialog = true;
      if (this.paid_contracts == null) await this.get_paid_contracts();
      this.progress_dialog = false;
      return true;
    },
    print_data() {
      var url = this.default_server_url + "paid_contracts/" + this.token;
      window.open(url);
    },
    search_for() {
      this.contract_headers.forEach((c) => {
        if (
          c.value == "profile" ||
          c.value == "payment" ||
          c.value == "delete"
        ) {
          console.log("no action");
        } else {
          if (this.selected_header == c.value) {
            console.log("found");
            c.filterable = true;
          } else {
            c.filterable = false;
          }
        }
      });
      console.log(this.contract_headers);
    },
    payment(item) {
      switch (this.user.permission) {
        case "5":
          this.$router.push("/payment/" + item.id);
          break;
        case "4":
          this.$router.push("/payment/" + item.id);
          break;
        case "3":
          this.$router.push("/payment/" + item.id);
          break;
        case "2":
          this.$router.push("/payment/" + item.id);
          break;
        case "1":
          this.set_notification({
            message: "You are not allowed to do this action!",
            color: "red",
            showing: true,
            icon: "mdi-alert",
          });
          break;
        case "0":
          break;
      }
    },

    profile(item) {
      if (this.user.pemission == 0) {
        this.set_notification({
          message: "You are not allowed to do this action!",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      } else {
        this.$router.push("profile/" + item.id);
      }
    },
  },
  computed: {
    ...mapGetters({
      paid_contracts: "contract/paid_contracts",
      user: "auth/user",
      token: "auth/token",
      default_server_url: "auth/default_server_url",
    }),
  },
};
</script>
<style></style>
