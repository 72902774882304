<template>
  <v-container>
    <div>
      <v-card class="mb-5">
        <v-card-text>
          <add-branch-modal />
          <v-data-table
            v-if="branches"
            dense
            sort-by=""
            :headers="branch_headers"
            :items="branches"
            multi-sort
          >
            <!-- edit branch -->
            <template v-slot:[`item.edit`]="{ item }">
              <div class="text-center">
                <v-icon
                  class="mr-2"
                  color="yellow en-2"
                  @click="edit_branch(item)"
                >
                  mdi-pencil
                </v-icon>
                <span class="caption yellow--text text--en-2">
                  <strong>Edit</strong>
                </span>
              </div>
            </template>
            <!-- delete branch -->
            <!-- <template v-slot:[`item.delete`]="{ item }">
              <div class="text-center">
                <v-icon class="mr-2" color="red" @click="del_branch(item)">
                  mdi-delete
                </v-icon>
                <span class="caption red--text">
                  <strong>Delete</strong>
                </span>
              </div>
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="proceed_deletion"
        @cancel-event="close_confirm"
      />
    </div>
    <progress-linear :dialog="progress_dialog" />
    <edit-branch-modal
      v-if="edit_dialog"
      :item="edit_item"
      @close_edit_branch="close_edit_branch"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ProgressLinear from "@/components/ProgressLinear.vue";
import ConfirmNotification from "@/components/ConfirmNotification.vue";
import AddBranchModal from "../components/AddBranchModal.vue";
import EditBranchModal from "@/components/EditBranchModal.vue";

export default {
  components: {
    ProgressLinear,
    ConfirmNotification,
    AddBranchModal,
    EditBranchModal,
  },
  data() {
    return {
      edit_dialog: false,
      edit_item: null,
      progress_dialog: false,

      alert_message: "",
      show_alert: false,
      branch_headers: [
        { text: "", value: "edit" },
        { text: "", value: "delete" },
        { text: "ID", value: "id" },
        { text: "Branch Name", value: "branch_name" },
        { text: "Branch Location", value: "branch_location" },
        { text: "Date Created", value: "created_at" },
        { text: "Date Updated", value: "updated_at" },
      ],
    };
  },
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      delete_branch: "branch/delete_branch",
      get_branches: "branch/get_branches",
    }),
    edit_branch(item) {
      this.edit_item = item;
      this.edit_dialog = true;
    },
    close_edit_branch() {
      this.edit_dialog = false;
      this.edit_item = null;
      console.log("asd");
    },
    del_branch(item) {
      this.delete_branch(item.id).then(() => {
        this.set_notification({
          message: "You have successfuly deleted a branch",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
      });
    },
  },
  computed: {
    ...mapGetters({
      branches: "branch/branches",
    }),
  },
  async created() {
    this.progress_dialog = true;
    if (!this.branches) {
      this.get_branches().then(() => {
        this.progress_dialog = false;
      });
    } else {
      this.progress_dialog = false;
    }
  },
};
</script>
