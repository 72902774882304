<template>
  <div>
    <div class="hidden-md-and-down">
      <v-img src="@/assets/mata_background.jpg">
        <login-big-screen />
      </v-img>
    </div>
    <div class="hidden-md-and-up">
      <login-small-screen />
    </div>
  </div>
</template>

<script>
import LoginBigScreen from "../components/LoginBigScreen.vue";
import LoginSmallScreen from "../components/LoginSmallScreen.vue";
export default {
  components: {
    LoginBigScreen,
    LoginSmallScreen,
  },
};
</script>

<style></style>

LoginBigScreen
