<template>
  <v-container>
    <v-btn @click="open()" class="rounded-xl white--text" color="blue"
      ><v-icon class="mr-2">mdi-account-plus</v-icon>Add Account</v-btn
    >
    <v-dialog persistent v-model="dialog" max-width="800">
      <v-card>
        <v-sheet color="blue darken-2">
          <div class="pa-10">
            <h2 class="white--text display-1">Add Account</h2>
          </div>
        </v-sheet>
        <v-card-text>
          <v-form ref="form" class="mt-5">
            <v-row>
              <v-col
                ><v-text-field
                  label="Username"
                  prepend-icon="mdi-account"
                  v-model="form.username"
                  :rules="rule"
                ></v-text-field>
                <v-text-field
                  type="password"
                  label="Password"
                  prepend-icon="mdi-form-textbox-password"
                  v-model="form.password"
                  :rules="rule"
                ></v-text-field>
                <v-text-field
                  type="password"
                  label="Confirm Password"
                  prepend-icon="mdi-form-textbox-password"
                  v-model="form.confirm_password"
                  :rules="rule"
                ></v-text-field>
                <v-text-field
                  label="First Name"
                  prepend-icon="mdi-account-outline"
                  v-model="form.first_name"
                  :rules="rule"
                ></v-text-field>
                <v-text-field
                  label="Middle Name"
                  prepend-icon="mdi-account-outline"
                  v-model="form.middle_name"
                  :rules="rule"
                >
                </v-text-field
              ></v-col>
              <v-col>
                <v-text-field
                  label="Last Name"
                  prepend-icon="mdi-account-outline"
                  v-model="form.last_name"
                  :rules="rule"
                ></v-text-field>
                <v-select
                  :items="gender"
                  label="Gender"
                  prepend-icon="mdi-gender-male-female"
                  v-model="form.gender"
                  :rules="rule"
                ></v-select>
                <v-text-field
                  label="Address"
                  prepend-icon="mdi-map-marker"
                  v-model="form.address"
                  :rules="rule"
                ></v-text-field>
                <v-text-field
                  label="Phone Number"
                  prepend-icon="mdi-cellphone"
                  v-model="form.phone_number"
                  :rules="rule"
                ></v-text-field>
                <v-select
                  :items="roles"
                  prepend-icon="mdi-account-hard-hat"
                  label="Role"
                  v-model="form.role"
                  :rules="rule"
                ></v-select
              ></v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex-row justify-end">
          <v-btn
            @click="add"
            color="primary"
            :loading="button_loading"
            class="rounded-xl"
            >Add</v-btn
          >
          <v-btn @click="close" class="rounded-xl">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <progress-linear :dialog="progress_dialog" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  components: { ProgressLinear },
  data() {
    return {
      roles: [
        "Administrator",
        "Verifier",
        "Collector",
        "Cashier",
        "Encoder",
        "Contract Validator",
      ],
      gender: ["Male", "Female"],
      rule: [(v) => v.length >= 1 || "This field is required"],
      button_loading: false,
      dialog: false,
      form: {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        address: "",
        username: "",
        confirm_password: "",
        password: "",
        account_role: "",
        phone_number: "",
        role: "",
      },
      progress_dialog: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      //   add_branch: "branch/add_branch",
      add_account: "account/add_account",
    }),
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    add() {
      if (this.form.password != this.form.confirm_password) {
        alert("password does not match");
      } else {
        if (this.$refs.form.validate()) {
          this.button_loading = true;
          this.progress_dialog = true;
          this.add_account(this.form).then(() => {
            this.button_loading = false;
            this.progress_dialog = false;
            this.set_notification({
              message: "You have successfully added a new account!",
              color: "success",
              showing: true,
              icon: "mdi-check",
            });
          });
        } else {
          this.set_notification({
            message: "Please check all fields",
            color: "error",
            showing: true,
            icon: "mdi-alert",
          });
        }
      }
    },
  },
};
</script>

<style></style>
