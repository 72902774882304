import axios from "axios";
export default {
  namespaced: true,
  state: {
    branches: null,
  },
  getters: {
    branches(state) {
      return state.branches;
    },
  },
  mutations: {
    SET_BRANCHES(state, branches) {
      state.branches = branches;
    },
    ADD_BRANCH(state, branch) {
      state.branches.unshift(branch);
    },
    DELETE_BRANCH(state, branch_id) {
      let filtered = state.branches.filter((b) => b.id != branch_id);
      state.branches = filtered;
    },
    EDIT_BRANCH(state, branch) {
      state.branches.forEach((b) => {
        if (b.id == branch.id) {
          b = branch;
        }
      });
    },
  },
  actions: {
    // get agents
    async get_branches({ commit }) {
      let response = await axios.get("/branches");
      commit("SET_BRANCHES", response.data);
    },
    async add_branch({ commit }, request) {
      let response = await axios.post("/branch", request);
      commit("ADD_BRANCH", response.data);
    },
    async delete_branch({ commit }, branch_id) {
      let response = await axios.delete("/branch/" + branch_id);
      commit("DELETE_BRANCH", response.data);
    },
    async edit_branch({ commit }, request) {
      let response = await axios.put("/branch", request);
      commit("EDIT_BRANCH", response.data);
    },
  },
};
