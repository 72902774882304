<template>
  <v-dialog v-model="dialog" max-width="350" persistent>
    <v-card>
      <v-sheet color="blue" class="pa-5 white--text">
        <h2>Edit Payment</h2>
      </v-sheet>
      <v-card-text>
        <v-form ref="form" class="mt-6">
          <v-text-field
            ref="name"
            class="rounded-xl"
            filled
            v-model="item.contract_id"
            label="Contract ID"
            prepend-icon="mdi-calendar"
            readonly
          ></v-text-field>
          <v-text-field
            ref="name"
            class="rounded-xl"
            filled
            v-model="item.payment_date"
            label="Payment Date"
            prepend-icon="mdi-calendar"
          ></v-text-field>
          <v-text-field
            ref="name"
            class="rounded-xl"
            filled
            v-model="item.amount_paid"
            label="Amount Paid"
            prepend-icon="mdi-cash"
          ></v-text-field>
          <v-text-field
            ref="name"
            class="rounded-xl"
            filled
            v-model="item.pr_number"
            label="PR Number"
            prepend-icon="mdi-file"
          ></v-text-field>
          <v-text-field
            ref="name"
            class="rounded-xl"
            filled
            v-model="item.posted_by"
            label="Posted By"
            prepend-icon="mdi-account"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="blue" class="white--text" @click="submit_edit"
          >Submit</v-btn
        >
        <v-btn color="" class="--text" @click="close_modal">Cancel</v-btn>
      </v-card-actions>
    </v-card>
    <div v-if="show_alert">
      <confirm-notification
        icon="mdi-alert-outline"
        class="alert"
        type="warning"
        :message="this.alert_message"
        @confirm-event="proceed_edit"
        @cancel-event="close"
      />
    </div>
    <progress-linear :dialog="progress_dialog" />
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmNotification from "./ConfirmNotification.vue";
import ProgressLinear from "./ProgressLinear.vue";
export default {
  components: { ConfirmNotification, ProgressLinear },
  props: ["item"],
  data() {
    return {
      progress_dialog: false,
      dialog: true,
      show_alert: false,
      alert_message: "Are you sure you want to edit this payment?",
    };
  },
  created() {
    console.log(this.item);
  },
  methods: {
    ...mapActions({
      edit_payment: "payment/edit_payment",
      set_notification: "notification/set_notification",
    }),

    submit_edit() {
      this.show_alert = true;
    },
    close_modal() {
      this.$emit("close_edit_payment_modal");
    },
    close() {
      this.show_alert = false;
    },
    proceed_edit() {
      this.show_alert = false;
      this.progress_dialog = true;
      this.edit_payment(this.item).then(() => {
        this.set_notification({
          message: "You have successfully edited an item",
          color: "success",
          showing: true,
          icon: "mdi-check",
        });
        this.progress_dialog = false;
      });
    },
  },
};
</script>

<style></style>
