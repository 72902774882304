<template>
  <v-container>
    <v-dialog persistent v-model="dialog" max-width="500">
      <v-card>
        <v-sheet color="blue darken-2">
          <div class="pa-10">
            <h2 class="white--text display-1">Add Branch</h2>
          </div>
        </v-sheet>
        <v-card-text>
          <v-form ref="form" class="mt-5">
            <v-text-field
              label="Branch Name"
              prepend-icon="mdi-office-building-marker"
              v-model="item.branch_name"
            ></v-text-field>
            <v-text-field
              label="Branch Location"
              prepend-icon="mdi-office-building-marker"
              v-model="item.branch_location"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex-row justify-end">
          <v-btn @click="edit" color="primary" :loading="button_loading"
            >Add</v-btn
          >
          <v-btn @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <progress-linear :dialog="progress_dialog" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ProgressLinear from "@/components/ProgressLinear.vue";
export default {
  components: { ProgressLinear },
  props: ["item"],
  data() {
    return {
      rules: {
        number_rule: [(v) => v.length >= 1 || "This field is required*"],
        age_rule: [(v) => v.length >= 1 || "Minimum length is 1 character"],
      },
      dialog: true,
      progress_dialog: false,
      form: {
        branch_name: "",
        branch_location: "",
      },
      button_loading: false,
    };
  },
  computed: {},
  created() {},
  methods: {
    ...mapActions({
      set_notification: "notification/set_notification",
      edit_branch: "branch/edit_branch",
    }),
    open() {
      this.dialog = true;
    },
    close() {
      this.$emit("close_edit_branch");
    },
    edit() {
      if (this.$refs.form.validate()) {
        this.progress_dialog = true;
        this.edit_branch(this.item).then(() => {
          this.progress_dialog = false;
          this.set_notification({
            message: "You have successfully edited a new agent",
            color: "success",
            showing: true,
            icon: "mdi-check",
          });
        });
      } else {
        this.set_notification({
          message: "Please check all fields",
          color: "error",
          showing: true,
          icon: "mdi-alert",
        });
      }
    },
  },
};
</script>

<style></style>
