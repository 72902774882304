<template>
  <v-row>
    <v-col cols="4">
      <!-- SEARCH FROM -->
      <v-menu
        ref="menu_from"
        v-model="menu_from"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.from_date"
            label="From:"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="form.from_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" small @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" small @click="save_from"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <!-- SEARCH TO -->
    <v-col cols="4">
      <v-menu
        ref="menu_to"
        v-model="menu_to"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="form.to_date"
            label="To:"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="form.to_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="save_to"> OK </v-btn>
        </v-date-picker>
      </v-menu></v-col
    >
    <v-col>
      <br /><v-btn
        text
        small
        color="grey"
        class="mr-2"
        dark
        @click="filter_contracts"
        >Search</v-btn
      ><v-btn color="grey" dark @click="revert" small text>Revert</v-btn></v-col
    >
    <progress-linear :dialog="progress_dialog" />
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import ProgressLinear from "./ProgressLinear.vue";
// import moment from "moment";
export default {
  components: { ProgressLinear },
  data() {
    return {
      progress_dialog: false,
      form: {
        from_date: "",
        to_date: "",
      },
      menu_from: false,
      menu_to: false,
      date: "",
    };
  },

  methods: {
    ...mapActions({
      filter: "contract/filter_contracts",
      get_contracts: "contract/get_contracts",
    }),
    revert() {
      this.progress_dialog = true;
      this.get_contracts().then(() => {
        this.progress_dialog = false;
      });
    },
    filter_contracts() {
      console.log(this.form);
      this.progress_dialog = true;
      this.filter(this.form).then(() => {
        this.progress_dialog = false;
      });
    },
    save_from() {
      // var date = moment(this.form.from_date, ["YYYY-MM-DD"]).format(
      //   "YYYY-DD-MM"
      // );
      // this.form.from_date = date;
      this.$refs.menu_from.save(this.form.from_date);
    },
    save_to() {
      // var date = moment(this.form.to_date, ["YYYY-MM-DD"]).format("YYYY-DD-MM");
      // this.form.to_date = date;
      this.$refs.menu_to.save(this.form.to_date);
    },
  },
};
</script>

<style></style>
