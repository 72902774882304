import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        background: colors.grey.lighten3, // Not automatically applied
        // background: "#272727", // Not automatically applied
        blue: "#012061",
        dark_grey: "#272727",
      },
    },
  },
});
